import * as ActionTypes from './types';


const INITIAL_STATE = {
  loggedIn: false,
  authUser: {
  }
};


const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_AUTH_USER: {
      if (action.authUser === null) {
        return INITIAL_STATE;
      }

      //let user = Object.assign({}, state.authUser, action.authUser);
      let user = {...state.authUser, ...action.authUser};
      return {
        ...state,
        loggedIn: true,
        authUser: user
      };
    }
    
    case ActionTypes.SESSION_LOGIN: {
      let user = {...state.authUser, ...action.authUser};
      return {
        ...state,
        loggedIn: true,
        authUser: user
      };
    }

    case ActionTypes.SESSION_LOGOUT: {
      window.localStorage.removeItem("authUser");
      window.location.reload(false);
      return INITIAL_STATE;
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
