import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Pexels from "../../assets/images/pexels-alexander-tiupa-192136.jpg";
//import SocialIconOne from "../../assets/images/Social Media Icons-01.svg";
import SocialIconTwo from "../../assets/images/Social Media Icons-02.svg";
import SocialIconThree from "../../assets/images/Social Media Icons-03.svg";
import SocialIconFour from "../../assets/images/Social Media Icons-04.svg";
import CoinMergeIcon from "../../assets/images/SocialMedia-coinmerge.png"; 

import {
  Toast,
  ToastSuccess,
  ToastError
} from "../../components";



const Community = () => {
  const SubscribeForm = ({ status, message, onValidated }) => {

    useEffect(() => {
      if (status === "sending") {
        Toast("subscribing...");
      } else if (status === "error") {
        ToastError(message);
      } else if (status === "success") {
        ToastSuccess(message);
      }
    }, [status, message]);

    let email;

    const subscribe = () => {
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
    };

    return (
      <>
        <ToastContainer />
        <div className="mb-4 mt-4">
          <input
            className="form-control"
            style={{
              background: "#eff1f4",
              border: "none",
              borderRadius: "16px",
              boxShadow: "none",
              height: "64px",
              padding: "16px",
              minWidth: "250px",
              outline: "none",
              fontSize: "20px",
              textIndent: "8px",
            }}
            // style={{height: "64px", fontSize: "22px", padding: "16px", paddingLeft: "32px", borderRadius: "16px"}}
            type="email"
            name="email"
            placeholder="Email address"
            ref={node => (email = node)}
          />
        </div>
        <button
          className="btn btn-lg"
          style={{
            width: "240px",
            background: "#13863f",
            border: "none",
            borderRadius: "8px",
            fontSize: "18px",
          }}
          onClick={e => subscribe()}
        >
          Subscribe
        </button>
      </>
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${Pexels})`,
        backgroundSize: "cover",
        minHeight: "50vh",
        maxHeight: "100vh",
      }}
    >
      <div
        className="container"
        style={{ paddingTop: "100px", paddingBottom: "50px" }}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <p
                style={{ color: "#ffffff", fontSize: "44px", fontWeight: 500, lineHeight: "1.5em" }}
              >
                Join The Community
              </p>
              <div className="row justify-content-start">
                <button
                  className="btn btn-simple"
                  style={{ padding: 0, marginRight: 8 }}
                >
                  <a href={"https://app.coinmerge.io/chat/carboneco"}>
                    <img
                      src={CoinMergeIcon}
                      alt="telegram"
                      style={{ width: "75px" }}
                    />
                  </a>
                </button>
                <button
                  className="btn btn-simple"
                  style={{ padding: 0, marginRight: 8 }}
                >
                  <a href={"https://twitter.com/absorbco2"}>
                    <img
                      src={SocialIconTwo}
                      alt="twitter"
                      style={{ width: "75px" }}
                    />
                  </a>
                </button>
                <button
                  className="btn btn-simple"
                  style={{ padding: 0, marginRight: 8 }}
                >
                  <a href={"https://www.linkedin.com/company/carboneco"}>
                    <img
                      src={SocialIconThree}
                      alt="linkedin"
                      style={{ width: "75px" }}
                    />
                  </a>
                </button>
                <button
                  className="btn btn-simple"
                  style={{ padding: 0, marginRight: 8 }}
                >
                  <a href={"https://www.reddit.com/r/carboneco"}>
                    <img
                      src={SocialIconFour}
                      alt="reddit"
                      style={{ width: "75px" }}
                    />
                  </a>
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-4">
              <p
                style={{ color: "#ffffff", fontSize: "44px", fontWeight: 500, lineHeight: "1.5em" }}
              >
                Stay in the loop
              </p>
              <MailchimpSubscribe
                url={process.env.REACT_APP_MAILCHIMP_URL}
                render={({ subscribe, status, message }) => (
                  <SubscribeForm
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
