import React from "react";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";

const Loading = () => {
  const uiState = useSelector((state) => state.uiState);
  const { theme_mode } = uiState;

  return (
    <div style={{
        display: "flex",
        width: "100%",
        height: "100%",
        minHeight: "300px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ReactLoading
        type="bars"
        color={theme_mode === "dark" ? "#FFFFFF" : "#004000"}
      />
    </div>
  );
};

export default Loading;
