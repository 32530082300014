import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Fortmatic from "fortmatic";
import axios from 'axios';

import Coinbase from '../assets/images/coinbase.svg';
import { ToastError } from '../components';
import {
  createVoucher,
  updateVoucher
} from "./apiService";
import NFTMarket from '../artifacts/NFTMarket.json'
import NFT from '../artifacts/NFT.json'
import OffsetNFTDataStorage from '../artifacts/ledger.json'
import LazyMinter from "./LazyMinter";
import { setWalletInfo } from "../redux/actions";
import Web3Token from 'web3-token';
import {dgw} from "./utilsService";
//const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
const networkId = process.env.REACT_APP_NETWORK;
const chainId = process.env.REACT_APP_CHAIN_ID;


const nftmarketaddress = process.env.REACT_APP_NFT_MARKET_ADDRESS;
const nftaddress = process.env.REACT_APP_NFT_ADDRESS;
const dividendWallet = process.env.REACT_APP_DIVIDEND_WALLET;
const ledgerContract = process.env.REACT_APP_NFT_LEDGER;
//const IPFS_ID = process.env.REACT_APP_INFURA_IPFS_ID;
//const IPFS_SECRET = process.env.REACT_APP_INFURA_IPFS_SECRET;

//const infuraTokenId = process.env.REACT_APP_INFURA_ID;
//axios.defaults.withCredentials = true

const providerOptions = {
  "custom-coinbase": {
    display: {
      logo: Coinbase,
      name: "Coinbase",
      description: "Scan with WalletLink to connect",
    },
    options: {
      appName: "app", // Your app name
      networkUrl: `https://${networkId}.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
      chainId: chainId,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_ID,
    },
  },
  fortmatic: {
    package: Fortmatic, // required
    options: {
      key: process.env.REACT_APP_FORTMATIC_KEY  // required
    }
  }
};

let signer = null;
let provider = null;
let walletAddress = null;

export const getTransaction = async (tokenId) => {
  if (tokenId) {
    //const { provider } = setNetwork();

    const provider = new ethers.providers.InfuraProvider(
      process.env.REACT_APP_NETWORK,
      process.env.INFURA_API_KEY
    );
    const marketContract = new ethers.Contract(nftmarketaddress, NFTMarket.abi, provider);
    const result = await marketContract.getTradeHistory(tokenId);
    return result;
  } else
    return [];
}

// export const getETH = async () => {
//   const web3 = new Web3('http://localhost:8545');
//   await web3.eth.sendTransaction({
//     from: "0x70997970c51812dc3a010c7d01b50e0d17dc79c8",
//     to: '0x5414B7aeDAc611d80A64eb6395819f96B19CD475',
//     value: web3.utils.toWei('1000', "ether")
//   });
//   await web3.eth.sendTransaction({
//     from: "0x70997970c51812dc3a010c7d01b50e0d17dc79c8",
//     to: '0x889429524408F728046F799A3c000d5276C8083c',
//     value: web3.utils.toWei('1000', "ether")
//   })
// }

export const connectWallet = async (dispatch) => {
  // console.log("connectWallet");
  const web3Modal = new Web3Modal({
    network: process.env.REACT_APP_NETWORK, // optional
    // network: 'testnet', // TestNet
    cacheProvider: true, // optional
    providerOptions
  })

  const connection = await web3Modal.connect();
  provider = new ethers.providers.Web3Provider(connection);
  signer = provider.getSigner();

  const { name } = await provider.getNetwork();
  if (name !== process.env.REACT_APP_NETWORK.toLowerCase()) {
    ToastError("Wrong Network");
    //console.log(name);
    return false;
  }

  const myAddress = await signer.getAddress();
  if (myAddress && myAddress !== "") {
    walletAddress = myAddress;
    const walletInfo = {
      walletAddress: myAddress,
      provider: provider,
      signer: signer
    }
    dispatch(setWalletInfo(walletInfo));
    // dispatch({
    //   type: "CHANGE_WALLET_INFO",
    //   payload: {walletAddress: myAddress, provider: provider, signer: signer},
    // });
    const token = await Web3Token.sign(async msg => await signer.signMessage(msg), {
      domain: "carboneco.trade",
      statement: 'I accept the Carbon Eco Terms of Service: https://carboneco.trade/faq',
      expire_in: '1 days'
    });
    let data = {wallet: walletAddress, token: token};

    return data;
  } else
    return false;
};

const setNetwork = () => {
  const provider = new ethers.providers.JsonRpcProvider(`https://${process.env.REACT_APP_NETWORK}.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`);
  // const provider = new ethers.providers.JsonRpcProvider(`http://localhost:8545`);
  const signer = provider.getSigner();
  return { provider, signer };
}

export const loadMyNFTs = async () => {
  // if (!signer || !provider)
  //   connectWallet(dispatch);
  const marketContract = new ethers.Contract(nftmarketaddress, NFTMarket.abi, signer);
  const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);
  const data = await marketContract.fetchMyNFTs();
  //console.log(data);

  const items = await Promise.all(data.map(async i => {
    // console.log(i);
    //console.log(i?.tokenId.toNumber())
    const tokenUri = await tokenContract.tokenURI(i?.tokenId.toNumber());
    //console.log(tokenUri);
    const DGW = await dgw(tokenUri);
    //console.log(`${DGW}`);
    const meta = await axios.get(DGW);


    let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
    let item = {
      price,
      tokenId: i?.tokenId.toNumber(),
      currentOwner: i?.currentOwner,
      firstOwner: i?.firstOwner,
      itemId: i?.itemId.toNumber(),
      data: meta.data,
      tokenUri: tokenUri,
      sold: i?.sold
    }
    return item
  }));
  return items;
}

export const loadNFTs = async () => {
  const { provider } = setNetwork();
  const marketContract = new ethers.Contract(nftmarketaddress, NFTMarket.abi, provider);
  const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);
  const data = await marketContract.fetchMarketItems();

  const items = await Promise.all(data.map(async i => {
    const tokenUri = await tokenContract.tokenURI(i.tokenId)
    const DGW = await dgw(i?.voucher?.uri);
    const meta = await axios.get(DGW);
    let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
    let item = {
      price,
      tokenId: i?.tokenId.toNumber(),
      currentOwner: i?.currentOwner,
      firstOwner: i?.firstOwner,
      itemId: i?.itemId.toNumber(),
      data: meta.data,
      tokenUri: tokenUri,
      sold: i?.sold
    }
    return item
  }));
  return items;
}

export const createSale = async (url, verifierArr, percentages, price, co2_value, affiliate=null, _id) => {
  // if (!signer || !provider)
  //   connectWallet();
  const parsedPrice = ethers.utils.parseUnits(price?.toFixed(10).toString(), 'ether');
  let contract = new ethers.Contract(nftaddress, NFT.abi, signer);
  const lazyMinter = new LazyMinter({ contract, signer: signer });
  const _tokenId = new Date().getTime();
  if (!affiliate)
    affiliate = dividendWallet;
  console.log(_tokenId, url, walletAddress, verifierArr, percentages, affiliate, parsedPrice, co2_value, _tokenId);
  const voucher = await lazyMinter.createVoucher(_tokenId, url, walletAddress, verifierArr, percentages, affiliate, parsedPrice, co2_value, _tokenId);
  await createVoucher({ wallet: walletAddress, voucher: voucher, _id: _id });
  return true;
}

export const buyNFT = async (originPrice, price, data, minted = true, voucherData=null, affiliate=null) => {
  // if (!signer || !provider)
  //   connectWallet();
  const contract = new ethers.Contract(nftmarketaddress, NFTMarket.abi, signer);
  const nftContract = new ethers.Contract(nftaddress, NFT.abi, signer);
  const org_price = ethers.utils.parseUnits(Number(originPrice)?.toFixed(10).toString(), 'ether');
  const con_price = ethers.utils.parseUnits(Number(price)?.toFixed(10).toString(), 'ether');
  //console.log(voucherData)
  if (!minted) {
    if (voucherData && voucherData.voucher) {
      let voucher = voucherData.voucher;
      const sign = voucher?.signature;
      delete voucher.signature;
      await nftContract.createToken(nftmarketaddress, voucher, sign);
      await updateVoucher({ _id: voucherData._id });
    }

    if (!affiliate)
      affiliate = dividendWallet;
    const transaction = await contract.createMarketItem([data?.firstOwner], [100], nftaddress, data?.tokenId, org_price,  data?.verifierArr,  data?.percentages, affiliate);
    await transaction.wait();
  }

  const transaction1 = await contract.createMarketSale(nftaddress, data?.tokenId, org_price, {
    value: con_price
  });
  await transaction1.wait();
  return true;
}

export const burn = async (tokenId) => {
  const marketContract = new ethers.Contract(nftmarketaddress, NFTMarket.abi, signer);
  const transaction = await marketContract.burnOldOffset(tokenId);
  await transaction.wait();
  return true
}

export const reSale = async (tokenId) => {
  const marketContract = new ethers.Contract(nftmarketaddress, NFTMarket.abi, signer);
  const transaction = await marketContract.updateSale(nftaddress, tokenId);
  await transaction.wait();
  return true
}

export const getBalance = async () => {
  // if (!signer || !provider)
  //   connectWallet();
  const contract = new ethers.Contract(ledgerContract, OffsetNFTDataStorage.abi, signer);
  const balance = await contract.getFirstOwnerBalanceFromLedger(walletAddress);
  const convertedBalance = ethers.utils.formatEther(balance);
  console.log(`balance: ${convertedBalance}`)
  return convertedBalance;
}

export const withdraw = async () => {
  const contract = new ethers.Contract(nftmarketaddress, NFTMarket.abi, signer);
  contract.on("Payout", (address, balance) => {
    //console.log(address);
    //console.log(ethers.utils.formatEther(balance));
  });
  await contract.withdrawFirstOwnerBalance();
  return true;
}

export const convertBgNumberToString = (bgNumber) => {
  if (bgNumber)
    return ethers.utils.formatEther(bgNumber);
  else
    return "";
}

export const convertNumberToBigNumber = (num) => {
  if (num) {
    const price = ethers.utils.parseUnits(Number(num).toFixed(10).toString(), 'ether');
    return price;
  } else
    return 0;
}

export const getNFTItem = async (tokenId) => {
  const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);
  const marketContract = new ethers.Contract(nftmarketaddress, NFTMarket.abi, signer);
  const res = await marketContract.fetchItemByTokenId(tokenId);
  const tokenUri = await tokenContract.tokenURI(res.tokenId)
  const meta = await axios.get(tokenUri)
  let price = ethers.utils.formatUnits(res.price.toString(), 'ether')
  let item = {
    price,
    tokenId: res?.tokenId.toNumber(),
    currentOwner: res?.currentOwner,
    firstOwner: res?.firstOwner,
    itemId: res?.itemId.toNumber(),
    data: meta.data,
    tokenUri: tokenUri,
    sold: res?.sold
  }
  return item
}

// Items
export const getItems = async (data) => {
  const items = await Promise.all(data.map(async i => {
    const DGW = await dgw(i?.voucher?.uri);
    //console.log(`${DGW}, ${i?.voucher?.uri}`);
    const meta = await axios.get(DGW);
    const price = ethers.utils.formatEther(i?.voucher?.price);
    let item = {
      _id: i?._id,
      price: price,
      tokenId: i?.voucher?.tokenId,
      firstOwner: i?.voucher?.owner,
      data: meta.data,
      uri: i?.voucher?.uri,
      verifierArr: i?.voucher?.verifierAddr,
      percentages: i?.voucher?.percentagesForVerifiers,
      co2_value: i?.voucher?.co2_value,
      mintedDate: i?.voucher?.mintedDate
    }
    return item
  }));
  return items;
}


export const buyNFTGroup = async (
    tokenURI,
    tokenId,
    co2_value,
    verifierAddr,
    percentagesForVerifiers,
    firstOwnerAddr,
    priceAddr,
    percentagesForOwnerAddr,
    affiliateAddr,
    percentagesForAffiliateAddr,
    origin_price,
    price,
    affiliate
) => {

  try {
    const marketcontract = new ethers.Contract(nftmarketaddress, NFTMarket.abi, signer);
    const nftContract = new ethers.Contract(nftaddress, NFT.abi, signer);
    const org_price = ethers.utils.parseUnits(Number(origin_price)?.toFixed(10).toString(), 'ether');
    const con_price = ethers.utils.parseUnits(Number(price)?.toFixed(10).toString(), 'ether');
    // Mint

    const result = await nftContract.createGroupToken(tokenURI, tokenId, co2_value);
    let tx = await result.wait()
    let event = tx.events[0]
    let value = event.args[2]
    let _tokenId = value.toNumber();
    if (_tokenId !== tokenId)
      return;

    const transaction = await marketcontract.createGroupMarketItem(
        nftaddress,
        tokenId,
        verifierAddr,
        percentagesForVerifiers,
        firstOwnerAddr,
        priceAddr,
        percentagesForOwnerAddr,
        affiliateAddr,
        percentagesForAffiliateAddr,
        org_price,
        affiliate,
        {
          value: con_price
        });
    await transaction.wait();
    return true;
  }catch (e) {
    console.log(e);
    return false;
  }

}