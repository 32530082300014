import { NavLink, useLocation } from "react-router-dom";

import Logo from "../../../assets/images/CarbonEco-logo.svg";
import { NAVBARITEMS } from "../../../constants/navbarItem";
import * as ROUTES from "../../../constants/routes";




const footerItems = [
  {
    label: "C0 TOKEN",
    link: "https://v2.info.uniswap.org/token/0xbb3c2a170fbb8988cdb41c04344f9863b0f71c20"
  },
  {
    label: "Art",
    link: "https://rarible.com/nft-maniac/sale"
  },
  {
    label: "CONTACT",
    link: "https://discord.com/invite/cbEPj99Egm"
  }
];

const Footer = () => {
  const location = useLocation();

  return (
    <>
      <footer className="footer" style={{backgroundColor: "#ffffff"}}>
        <div className="container text-left" style={{marginTop: "32px", marginBottom: "32px"}}>
          <nav>
            <ul>
              <div className="row">
                <div className="col-md-4">
                  <NavLink 
                    to={ROUTES.LANDING}
                  >
                    <img src={Logo} alt="" style={{width: '250px'}} />
                  </NavLink>
                </div>
                <div className="col-md-8">
                  {NAVBARITEMS
                    .filter(item => item.footer)
                    .map((item, index) => 
                      <li
                        key={"menu-" + index}
                        className={`nav-item ${location?.pathname?.includes(item.value) ? 'active' : ''}`}
                      >
                        <NavLink
                          to={item.value}
                          style={{
                            fontSize: "16px", 
                            padding: "8px",
                            color: `${location?.pathname?.includes(item.value) ? '#13863f' : '#004000'}`,
                            borderRadius: 0,
                            borderBottom: `${location?.pathname?.includes(item.value) ? '2px solid #13863f' : '#004000'}`
                          }}
                        >
                          {item.label}
                        </NavLink>
                      </li>
                  )}
                  {footerItems.map((item, index) => 
                    <li
                      className={`nav-item ${location?.pathname?.includes(item.link) ? 'active' : ''}`}
                      key={"menu-other-" + index}
                    >
                      <a
                        style={{fontSize: "16px",
                        color: `${location?.pathname?.includes(item.value) ? '#13863f' : '#004000'}`,
                        }}
                        href={item.link}
                      >
                        {item.label}
                      </a>
                    </li>
                  )}
                </div>
              </div>
            </ul>
          </nav>
          <div> version info: {process.env.REACT_APP_VERSION} - on the {process.env.REACT_APP_NETWORK } Network</div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
