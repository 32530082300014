import React from "react";
import PropTypes from "prop-types";
import { numberWithCommas } from "../../services/utilsService";
import { ClipboardIcon } from "./ClipboardIcon";

const TitleFormat = ({ copy, title }) => (
  // <span className="font-bold flex w-max items-center">
  <span style={{
    display: "flex",
    fontSize: "16px",
    fontWeight: 400,
    alignItems: "center",
  }}>
    {copy && <ClipboardIcon />}&nbsp;

    {Number.isNaN(Number.parseFloat(title)) || typeof title === "string"
      ? title
      : numberWithCommas(title, 18, true)}
  </span>
);

TitleFormat.defaultProps = {
  copy: true,
  title: "",
};

TitleFormat.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  copy: PropTypes.bool,
};

export { TitleFormat };
