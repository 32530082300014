import React, {Suspense} from "react";
import { BrowserRouter } from "react-router-dom";
import { Main } from './layouts';
import {SocketContext, socket} from './context/socket';


function App() {
    return (
        <SocketContext.Provider value={socket}>
            <Suspense fallback={<div className="loading" />}>
                      <BrowserRouter>
                        <Main />
                      </BrowserRouter>

             </Suspense>
        </SocketContext.Provider>
  );
}

export default App;
