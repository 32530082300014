import * as ActionTypes from "./types";

export const setThemeMode = (theme_mode) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_THEME_MODE,
    theme_mode,
  });
};

export const setBlur = (blur) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_BLUR,
    blur,
  });
};

export const showSplashScreen = (show) => (dispatch) => {
  dispatch({
    type: ActionTypes.SHOW_SPLASH_SCREEN,
    show,
  });
};

export const requestDataPending = () => (dispatch) => {
  dispatch({
    type: ActionTypes.REQUEST_DATA_PENDING,
  });
};

export const requestDataFinished = () => (dispatch) => {
  dispatch({
    type: ActionTypes.REQUEST_DATA_FINISHED,
  });
};

export const showGlobalModal = (global_modal) => (dispatch) =>{
  dispatch({
    type: ActionTypes.SHOW_GLOBAL_MODAL,
    global_modal
  });
};