import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from "react-redux";
import App from './App';
import configureStore from './redux/store';
import reportWebVitals from './reportWebVitals';
import 'react-tippy/dist/tippy.css';

import "./assets/css/bootstrap.min.css";
import "./assets/css/material-kit.css?v=1.3.0";

const store = configureStore();

ReactDOM.render(
  <StoreProvider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </StoreProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
