import React, { lazy } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { AuthRoute } from './authRoute';
import AuthRedirect from './authRedirect';
import * as ROUTES from "../constants/routes";

const HomeLazy = lazy(() => import("../pages/Home"));
const BuyLazy = lazy(() => import("../pages/Buy"));
const TenantBuyLazy = lazy(() => import("../pages/TenantBuy"));
const SellLazy = lazy(() => import("../pages/Sell"));
const TenantSellLazy = lazy(() => import("../pages/TenantSell"));
const MyOffsetsLazy = lazy(() => import("../pages/MyOffsets"));
const BrandRegister = lazy(() => import("../pages/BrandRegister/BrandRegister"));
const MintLazy = lazy(() => import("../pages/Mint"));
// const JobsLazy = lazy(() => import ("../pages/Programs"));
const JobDetailLazy = lazy(() => import("../pages/JobDetail"));
const FaqLazy = lazy(() => import("../pages/Faq"));
const BlogLazy = lazy(() => import("../pages/Blog"));
const TeamLazy = lazy(() => import("../pages/Team"));
const NotFoundLazy = lazy(() => import("../pages/NotFound"));


// ce-119
const NFTOwnedDetail = lazy( ()=> import('../pages/NftOwned'))

const ProgramsLazy = lazy(() => import ("../pages/Programs"));

// CE-280
const EventPlannerLazy =  lazy(() => import ("../pages/EventPlanner"));

const Routes = () => (
  <Switch>
    <AuthRedirect exact path={ROUTES.LANDING} component={HomeLazy} />
    <Route exact path={ROUTES.LANDING} component={HomeLazy} />

    <AuthRoute exact path={ROUTES.SELL} component={SellLazy} />
    <AuthRoute exact path={ROUTES.MYOFFSETS} component={MyOffsetsLazy} />
    <AuthRoute exact path={ROUTES.BRAND} component={BrandRegister} />
    <AuthRoute exact path={ROUTES.MYOFFSETS_MY_NFT} component={MyOffsetsLazy} />
    <AuthRoute exact path={ROUTES.MYOFFSETS_MY_SUCCESS} component={MyOffsetsLazy} />
    <AuthRoute exact path={ROUTES.MYOFFSETS_MY_FAILED} component={MyOffsetsLazy} />
    <AuthRoute exact path={ROUTES.MYOFFSETS_MY_REVIEW} component={MyOffsetsLazy} />
    <AuthRoute exact path={ROUTES.MYOFFSETS_MY_MINT} component={MyOffsetsLazy} />
    <AuthRoute exact path={ROUTES.MINT} component={MintLazy} />
    <AuthRoute exact path={ROUTES.MINTED_DETAIL} component={JobDetailLazy} />
    <AuthRoute exact path={ROUTES.JOB_DETAIL} component={NFTOwnedDetail} />

    <Route exact path={ROUTES.BUY} component={BuyLazy} />
    <Route exact path={ROUTES.TENANT_SELL} component={TenantSellLazy} />
    <Route exact path={ROUTES.TENANT_BUY} component={TenantBuyLazy} />
    <Route exact path={ROUTES.PROGRAMS} component={ProgramsLazy} />
    <Route exact path={ROUTES.FAQ} component={FaqLazy} />
    <Route exact path={ROUTES.BLOG} component={BlogLazy} />
    <Route exact path={ROUTES.TEAM} component={TeamLazy} />
    <Route exact path={ROUTES.EVENT_PLANNER} component={EventPlannerLazy} />
    <Route exact path="/jobs">
      <Redirect to="/programs" />
    </Route>
    <Route exact path={ROUTES.WHITEPAPER} component={() => {
      window.location.href = 'https://carbon0public.s3.us-west-2.amazonaws.com/CarbonEco+2022+White+Paper.pdf';
      return null;
    }}
    />
    <Route exact path="/*" component={NotFoundLazy} />
  </Switch>
);

export { Routes };
