import {
  OFFSETS_TYPE_INDIVIDUAL,
  OFFSETS_TYPE_ORGANIZATION,
  OFFSETS_INDIVIDUALS,
  OFFSETS_ORGANIZATIONS
} from "../../constants/buy_offsets";


const Order = (props) => {
  // const onClickBuy = (link) => {
  //   console.log("onClickBuy :", link);
  // }

  return (
    <div className="row justify-content-between">
      <div className="col-md-6" style={{ textAlign: "center" }}>
        <div className="row mb-4">
          <h3
            style={{
              color: "#004d00",
              marginTop: "50px",
              fontSize: "32px",
              fontWeight: 800,
              textAlign: "center",
            }}
          >
            Individuals*
          </h3>
        </div>
        {OFFSETS_INDIVIDUALS.map((individual, index) => (
          <div className="row mt-4 mb-4" key={`order-individual-${index}`}>
            <button 
              className="btn btn-simple btn-lg"
              key={`order-individual-label-${index}`}
              style={{
                maraginRight: "8px",
                width: "140px",
                fontSize: "22px",
                cursor: "none",
                color: "#000000"
              }}
            >
              {individual.label}
            </button>
            <button 
              className="btn btn-lg"
              key={`order-individual-btn-${index}`}
              style={{
                width: "200px",
                background: "#13863f",
                border: "none",
                borderRadius: "8px",
                fontSize: "18px",
              }}
              onClick={e => props.onClickBuy(OFFSETS_TYPE_INDIVIDUAL, index)}
            >
              BUY NOW
            </button>
          </div>
        ))}
        <div className="row mb-4">
          <div className="col-md-12">
            <p
              style={{
                color: "#004d00",
                marginTop: "50px",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              *the average carbon footprint is 4 tons.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6" style={{ textAlign: "center" }}>
        <div className="row mb-4">
          <h3
            style={{
              color: "#004d00",
              marginTop: "50px",
              fontSize: "32px",
              fontWeight: 800,
              textAlign: "center",
            }}
          >
            Organizations
          </h3>
        </div>
        {OFFSETS_ORGANIZATIONS.map((organization, index) => (
          <div className="row mt-4 mb-4" key={`order-organization-${index}`}>
            <button 
              className="btn btn-simple btn-lg"
              key={`order-organization-label-${index}`}
              style={{
                marginRight: "16px",
                width: "150px",
                minWidth: "140px",
                fontSize: "22px",
                cursor: "none",
                color: "#000000"
              }}
            >
              {organization.label}
            </button>
            <button 
              className="btn btn-lg"
              key={`order-organization-btn-${index}`}
              style={{
                width: "200px",
                background: "#13863f",
                border: "none",
                borderRadius: "8px",
                fontSize: "18px",
              }}
              onClick={e => props.onClickBuy(OFFSETS_TYPE_ORGANIZATION, index)}
            >
              BUY NOW
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Order;
