import { useCallback, useEffect, useState, useRef } from "react";

const DisplayOption = (props) => {
  const { options, onSelected } = props;
  return options.map((item, i) => {
    return (
      <div className="custom-select-item"
        key={"option" + item.value + i}
        onClick={() => {
          onSelected(item);
        }}
      >
        {item.label}
      </div>
    );
  });
};

const useOutsideAlerter = (ref, closeDropdown) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeDropdown();
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, closeDropdown]);
};

const Selectbox = (props) => {
  const {
    options = [],
    selected = "",
    placeholder = "Select",
    onSelected,
    size = "1",
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const onOpen = useCallback(() => {
    if (options.length) setIsOpen(!isOpen);
  }, [setIsOpen, isOpen, options]);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useOutsideAlerter(wrapperRef, closeDropdown);

  return (
    <div style={{
        position: "relative",
        width: "100%",
        zIndex: `${isOpen ? 20 : 10}`,
      }}
    >
      <div className="custom-select-box" 
        style={{height: `${size === "3" ? "8px" : "55px"}`}}
        onClick={onOpen}
        ref={wrapperRef}
      >
        <label className="custom-select-label">
          {selected ? (
            selected === "Select" ? (
              placeholder ? (
                <span style={{color: "#ADADAD"}}>{placeholder}</span>
              ) : (
                selected
              )
            ) : (
              selected
            )
          ) : (
            <span style={{color: "#ADADAD"}}>{placeholder}</span>
          )}
        </label>
        <div className="custom-select-dropdown-cursor">
          {isOpen ? 
            <i className="fas fa-chevron-up"></i>
          :
            <i className="fas fa-chevron-down"></i>
          }
        </div>
      </div>
      {isOpen ? (
        <div className="select-option custom-select-dropdown-list"
        >
          <DisplayOption options={options} onSelected={onSelected} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Selectbox;
