import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "react-tippy";
import { TitleFormat } from "./TitleFormat";

const TooltipWrapper = ({ title, disabled, children, copy }) => {
  const [copiedText, setCopiedText] = useState("");

  const handleCopyClick = useCallback(() => {
    setCopiedText("Copied to clipboard");
    setTimeout(() => {
      setCopiedText("");
    }, 700);
  }, []);

  return (
    <Tooltip
      hideOnClick={false}
      html={copiedText ? <span style={{fontSize: "14px"}}>{copiedText}</span> : 
        <TitleFormat copy={copy} title={title} />
      }
      disabled={disabled}
    >
      {copy ? (
        <CopyToClipboard text={title} onCopy={handleCopyClick}>
          <span>{children}</span>
        </CopyToClipboard>
      ) : (
        <span>{children}</span>
      )}
    </Tooltip>
  );
};

TooltipWrapper.defaultProps = {
  disabled: false,
  copy: true,
  title: "",
};

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  copy: PropTypes.bool,
};

export default TooltipWrapper;
