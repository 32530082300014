import Dec from "decimal.js";
const IPFS_DGW = process.env.REACT_APP_INFURA_IPFS_DGW;

export const numberWithCommas = (
    x,
    decimals = 8,
    removeTrailingZeroes = true
) => {
  if (!Number.isFinite(parseFloat(x))) return "0";
  if (parseFloat(x).toString() === "0") return "0";

  const parts = Dec(x).toFixed(decimals).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  let formatted = parts.join(".");
  if (removeTrailingZeroes) {
    formatted = formatted
        .replace(/(\.\d*[1-9])0*$/, "$1") // 123.1200 -> 123.12
        .replace(/\.0*$/, "") // 123.00 -> 123
        .replace(/^0.0+$/, "0"); // 0.00 -> 0
  }
  return formatted;
};

export const formatNumber = (
    _num,
    decimals = 2,
    removeTrailingZeroes = true
) => {
  try {
    if (!Number.isFinite(parseFloat(_num))) return "0";
    const sign = parseFloat(_num) < 0 ? "-" : "";
    const num = Math.abs(parseFloat(_num));
    if (num < 10000)
      return numberWithCommas(_num, decimals, removeTrailingZeroes);

    const si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    let i;

    for (i = si.length - 1; i > 0; i -= 1) {
      if (num >= si[i].value) {
        break;
      }
    }

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    return (
        sign +
        Dec(num / si[i].value)
            .toFixed(decimals)
            .replace(rx, "$1") +
        si[i].symbol
    );
  } catch (e) {
    return _num.toString();
  }
};

export const shortenAddress = (address) =>
    `${address.slice(0, 5)}...${address.slice(-5)}`;

export const shortenedUrl = (url) =>
    `${url.slice(0,7)}...${url.slice(-2)}`;

export const dgw =(url)=>{
  //console.log(`URL: ${url}`)
  let hash = url.split('/').pop();
  const newDGW = IPFS_DGW + hash;
  return newDGW
}