import React from "react";
import Absorb from "../../assets/images/CarbonEco-logo.svg";
import Carbon from "../../assets/images/carbon.png";
import CarbonecoLogo from "../../assets/images/carboneco_logo.png";
import {
  Geotech,
  Agriculture,
  Ambient,
  Conserve,
  Electrify,
  Recycle,
  Transport,
  FoodChoice,
} from "./MethodLogo";
import { GetIcon } from "./GetIcon";
import { numberWithCommas } from "../../services/utilsService";
import {GetImageUncached} from "./GetImageUncached";

const PreviewImageTenant = React.forwardRef((props, ref) => {
  let selectedMethod = "";
  let logo = null;

  //console.log(props)

  if (props?.method?.toString().toLowerCase().includes("any")) {
    selectedMethod = (
      <text className="cls-13" transform="translate(140 1889.82)">
        MIXED METHODS
      </text>
    );
  } else if (props?.method?.toString().toLowerCase().includes("geotech")) {
    selectedMethod = (
      <text className="cls-13" transform="translate(306.07 1889.82)">
        GEOTECH
      </text>
    );
    logo = <Geotech />;
  } else if (props?.method?.toString().toLowerCase().includes("agriculture")) {
    selectedMethod = (
      <text className="cls-13" transform="translate(201.95 1893.94)">
        AGRICULTURE
      </text>
    );
    logo = <Agriculture />;
  } else if (props?.method?.toString().toLowerCase().includes("transport")) {
    selectedMethod = (
      <text className="cls-13" transform="translate(266.06 1892.65)">
        TRANSPORT
      </text>
    );
    logo = <Transport />;
  } else if (props?.method?.toString().toLowerCase().includes("ambient")) {
    selectedMethod = (
      <text className="cls-13" transform="translate(319.6 1895.95)">
        AMBIENT
      </text>
    );
    logo = <Ambient />;
  } else if (props?.method?.toString().toLowerCase().includes("conserve")) {
    selectedMethod = (
      <text className="cls-13" transform="translate(264.66 1894.82)">
        CONSERVE
      </text>
    );
    logo = <Conserve />;
  } else if (props?.method?.toString().toLowerCase().includes("electri")) {
    selectedMethod = (
      <text className="cls-13" transform="translate(289.19 1892.82)">
        ELECTRIFY
      </text>
    );
    logo = <Electrify />;
  } else if (props?.method?.toString().toLowerCase().includes("recycle")) {
    selectedMethod = (
      <text className="cls-13" transform="translate(324.19 1892.82)">
        RECYCLE
      </text>
    );
    logo = <Recycle />;
  } else if (props?.method?.toString().toLowerCase().includes("food")) {
    selectedMethod = (
      <text className="cls-13" transform="translate(205 1892.82)">
        FOOD CHOICE
      </text>
    );
    logo = <FoodChoice />;
  } else {
    selectedMethod = (
      <text className="cls-13" transform="translate(306.07 1889.82)">
        {props?.method?.toString()}
      </text>
    );
  }

  let verifyLogo = <g></g>;
  switch (props?.verification) {
    case "attest":
      verifyLogo = (
        <g>
          <use transform="translate(479 1625) scale(0.23)" xlinkHref="#image" />
        </g>
      );
      break;
    case "inspect":
      verifyLogo = (
        <g>
          <use
            transform="translate(418.56 1624.62) scale(0.23)"
            xlinkHref="#image"
          />
          <use
            transform="translate(539.69 1624.15) scale(0.23)"
            xlinkHref="#image"
          />
        </g>
      );
      break;
    case "audit":
      verifyLogo = (
        <g>
          <use
            transform="translate(479.12 1623.59) scale(0.23)"
            xlinkHref="#image"
          />
          <use
            transform="translate(602.47 1623.59) scale(0.23)"
            xlinkHref="#image"
          />
          <use
            transform="translate(355.78 1623.59) scale(0.23)"
            xlinkHref="#image"
          />
        </g>
      );
      break;
    case "certify":
      verifyLogo = (
        <g>
          <use
            transform="translate(542.2 1623.69) scale(0.23)"
            xlinkHref="#image"
          />
          <use
            transform="translate(668.36 1623.69) scale(0.23)"
            xlinkHref="#image"
          />
          <use
            transform="translate(416.05 1623.69) scale(0.23)"
            xlinkHref="#image"
          />
          <use
            transform="translate(289.89 1623.69) scale(0.23)"
            xlinkHref="#image"
          />
        </g>
      );
      break;
    default:
      break;
  }

  let url = CarbonecoLogo;


  if(props?.tenant){
    url = props?.tenantIcon
  }else{
    if (props?.countryCode) {
      url = GetIcon({ name: props?.countryCode });
    }
  }

  const carbonValue = props?.value / 1000;
  let strCarbonValue = "0.00";
  if (carbonValue < 10.0) {
    strCarbonValue = numberWithCommas(carbonValue, 5, false);
  } else if (carbonValue >= 10.0 && carbonValue < 100.0) {
    strCarbonValue = numberWithCommas(carbonValue, 4, false);
  } else if (carbonValue >=100.0 && carbonValue < 1000.0) {
    strCarbonValue = numberWithCommas(carbonValue, 3, false);
  } else {
    strCarbonValue = numberWithCommas(carbonValue, 2, false);
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1080 1920"
      ref={ref}
    >
      <image id="image" width="534" height="534" xlinkHref={Carbon} />
      <g id="background">
        <rect className="cls-1" y="1798" width="1080" height="360" />
        <rect className="cls-2" y="1438" width="1080" height="360" />
        <rect className="cls-3" y="1079" width="1080" height="360" />
        <rect className="cls-4" y="719" width="1080" height="360" />
        <rect className="cls-5" y="-1" width="1080" height="360" />
        <rect className="cls-6" y="359" width="1080" height="360" />
        <line className="cls-7" x1="10" y1="359.5" x2="1070" y2="359.5" />
        <text className="cls-8" transform="translate(20.73 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(21.98 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(23.47 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(23.97 6.33)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text className="cls-8" transform="translate(51.81 8) scale(0.58)">
          2{" "}
        </text>
        <text className="cls-8" transform="translate(53.8 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(57.05 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(57.92 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(59.42 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(60.67 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(62.16 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(62.65 6.33)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text className="cls-8" transform="translate(157.92 8) scale(0.58)">
          {" "}
        </text>
        <text className="cls-8" transform="translate(158.3 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(161.55 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(162.42 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(163.92 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(165.17 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(166.66 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(167.16 6.33)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text className="cls-8" transform="translate(195 8) scale(0.58)">
          2{" "}
        </text>
        <text className="cls-8" transform="translate(196.99 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(200.24 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(201.11 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(202.61 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(203.86 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(205.35 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(205.84 6.33)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text className="cls-8" transform="translate(301.12 8) scale(0.58)">
          {" "}
        </text>
        <text className="cls-8" transform="translate(301.49 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(304.74 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(305.62 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(307.11 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(308.36 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(309.86 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(310.35 6.33)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text className="cls-8" transform="translate(338.2 8) scale(0.58)">
          2{" "}
        </text>
        <text className="cls-8" transform="translate(340.18 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(343.43 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(344.3 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(345.8 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(347.05 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(348.54 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(349.03 6.33)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text className="cls-8" transform="translate(444.31 8) scale(0.58)">
          {" "}
        </text>
        <text className="cls-8" transform="translate(444.69 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(447.93 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(448.81 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(450.3 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(451.55 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(453.05 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(453.54 6.33)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text className="cls-8" transform="translate(481.39 8) scale(0.58)">
          2{" "}
        </text>
        <text className="cls-8" transform="translate(483.37 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(486.62 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(487.49 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(488.99 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(490.24 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(491.73 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(492.23 6.33)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text className="cls-8" transform="translate(587.5 8) scale(0.58)">
          {" "}
        </text>
        <text className="cls-8" transform="translate(587.88 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(591.13 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(592 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(593.5 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(594.75 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(596.24 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(596.73 6.33)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text className="cls-8" transform="translate(624.59 8) scale(0.58)">
          2{" "}
        </text>
        <text className="cls-8" transform="translate(626.57 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(629.82 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(630.7 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(632.19 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(633.44 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(634.94 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(635.43 6.33)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text className="cls-8" transform="translate(730.71 8) scale(0.58)">
          {" "}
        </text>
        <text className="cls-8" transform="translate(731.09 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(734.34 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(735.21 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(736.7 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(737.95 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(739.45 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(739.94 6.33)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text className="cls-8" transform="translate(767.79 8) scale(0.58)">
          2{" "}
        </text>
        <text className="cls-8" transform="translate(769.78 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(773.03 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(773.9 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(775.4 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(776.65 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(778.14 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(778.64 6.33)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text className="cls-8" transform="translate(873.92 8) scale(0.58)">
          {" "}
        </text>
        <text className="cls-8" transform="translate(874.29 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(877.55 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(878.42 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(879.91 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(881.16 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(882.66 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(883.15 6.33)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text className="cls-8" transform="translate(911 8) scale(0.58)">
          2{" "}
        </text>
        <text className="cls-8" transform="translate(912.99 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(916.24 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(917.11 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(918.61 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(919.86 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(921.35 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(921.84 6.33)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text className="cls-8" transform="translate(1017.12 8) scale(0.58)">
          {" "}
        </text>
        <text className="cls-8" transform="translate(1017.5 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(1020.75 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(1021.63 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(1023.12 4.67) scale(0.58)">
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(1024.37 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(1025.87 4.67) scale(0.58)">
          .
        </text>
        <text className="cls-8" transform="translate(1026.36 6.33)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text className="cls-8" transform="translate(1054.21 8) scale(0.58)">
          2{" "}
        </text>
        <text className="cls-8" transform="translate(1056.2 6.33)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text className="cls-8" transform="translate(1059.45 4.67) scale(0.58)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(1060.32 6.33)">
          .{" "}
        </text>
        <text className="cls-8" transform="translate(1061.81 4.67) scale(0.58)">
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1060.77 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1059.52 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1058.03 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1057.53 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1029.69 1912.45) rotate(180) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1027.7 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1024.45 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1023.58 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1022.08 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1020.83 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1019.34 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1018.85 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(923.58 1912.45) rotate(180) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(923.2 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(919.95 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(919.08 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(917.58 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(916.33 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(914.84 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(914.34 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(886.5 1912.45) rotate(180) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(884.51 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(881.26 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(880.39 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(878.89 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(877.64 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(876.15 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(875.66 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(780.38 1912.45) rotate(180) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(780.01 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(776.76 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(775.88 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(774.39 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(773.14 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(771.64 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(771.15 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(743.3 1912.45) rotate(180) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(741.32 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(738.07 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(737.2 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(735.7 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(734.45 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(732.96 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(732.47 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(637.19 1912.45) rotate(180) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(636.81 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(633.57 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(632.69 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(631.2 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(629.95 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(628.45 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(627.96 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(600.11 1912.45) rotate(180) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(598.13 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(594.88 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(594.01 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(592.51 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(591.26 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(589.77 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(589.27 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(494 1912.45) rotate(180) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(493.62 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(490.37 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(489.5 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(488 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(486.75 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(485.26 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(484.77 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(456.91 1912.45) rotate(180) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(454.93 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(451.68 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(450.8 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(449.31 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(448.06 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(446.56 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(446.07 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(350.79 1912.45) rotate(180) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(350.41 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(347.16 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(346.29 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(344.8 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(343.55 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(342.05 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(341.56 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(313.71 1912.45) rotate(180) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(311.72 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(308.47 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(307.6 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(306.1 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(304.85 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(303.36 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(302.86 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(207.58 1912.45) rotate(180) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(207.21 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(203.95 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(203.08 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(201.59 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(200.34 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(198.84 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(198.35 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(170.5 1912.45) rotate(180) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(168.51 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(165.26 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(164.39 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(162.89 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(161.64 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(160.15 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(159.66 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(64.38 1912.45) rotate(180) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(64 1914.12) rotate(180)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(60.75 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(59.88 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(58.38 1915.78) rotate(180) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(57.13 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(55.63 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(55.14 1914.12) rotate(180)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(27.29 1912.45) rotate(180) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(25.3 1914.12) rotate(180)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(22.05 1915.78) rotate(180) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(21.18 1914.12) rotate(180)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(19.69 1915.78) rotate(180) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1909.11) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1907.86) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1906.37) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1905.87) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1810.6) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1810.22) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1806.97) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1806.1) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1804.61) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1803.36) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1801.86) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1801.37) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1773.52) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1771.54) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1768.29) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1767.41) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1765.92) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1764.67) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1763.17) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1762.68) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1667.41) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1667.03) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1663.78) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1662.91) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1661.42) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1660.16) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1658.67) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1658.18) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1654.93) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1654.55) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1651.3) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1650.43) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1648.93) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1647.68) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1646.19) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1645.7) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1617.85) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1615.86) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1612.61) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1611.74) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1610.25) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1609) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1607.5) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1607.01) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1511.74) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1511.36) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1508.11) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1507.24) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1505.74) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1504.49) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1503) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1502.5) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1499.25) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1498.88) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1495.63) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1494.75) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1493.26) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1492.01) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1490.51) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1490.02) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1462.17) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1460.19) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1456.94) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1456.07) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1454.57) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1453.32) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1451.83) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1451.34) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1356.06) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1355.68) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1352.44) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1351.56) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1350.07) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1348.82) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1347.32) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1346.83) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1343.58) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1343.2) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1339.95) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1339.08) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1337.59) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1336.34) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1334.84) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1334.35) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1306.5) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1304.52) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1301.27) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1300.4) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1298.9) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1297.65) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1296.16) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1295.66) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1200.39) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1200.01) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1196.76) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1195.89) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1194.4) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1193.15) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1191.65) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1191.16) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1187.91) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1187.53) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1184.28) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1183.41) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1181.91) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1180.66) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1179.17) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1178.68) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1150.83) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1148.84) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1145.59) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1144.72) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1143.23) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1141.98) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1140.48) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1139.99) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1044.72) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1044.34) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1041.09) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1040.22) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1038.72) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1037.47) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1035.98) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1035.49) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 1032.24) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1031.86) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1028.61) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 1027.74) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1026.24) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 1024.99) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 1023.5) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 1023) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 995.16) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 993.17) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 989.92) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 989.05) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 987.55) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 986.3) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 984.81) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 984.32) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 889.04) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 888.66) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 885.41) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 884.54) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 883.04) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 881.79) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 880.3) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 879.81) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 876.56) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 876.18) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 872.92) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 872.05) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 870.56) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 869.31) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 867.81) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 867.32) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 839.47) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 837.48) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 834.23) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 833.36) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 831.86) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 830.61) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 829.12) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 828.63) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 733.35) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 732.97) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 729.72) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 728.85) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 727.35) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 726.1) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 724.61) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 724.11) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 696.26) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 694.27) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 691.02) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 690.15) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 688.66) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 687.41) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 685.91) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 685.42) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 590.14) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 589.76) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 586.51) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 585.64) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 584.14) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 582.89) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 581.4) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 580.91) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 553.05) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 551.07) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 547.81) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 546.94) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 545.45) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 544.2) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 542.7) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 542.21) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 446.93) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 446.55) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 443.3) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 442.43) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 440.93) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 439.68) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 438.19) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 437.7) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 409.84) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 407.86) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 404.61) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 403.74) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 402.24) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 400.99) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 399.5) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 399) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 303.72) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 303.34) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 300.09) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 299.22) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 297.73) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 296.48) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 294.98) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 294.49) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 266.64) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 264.65) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 261.4) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 260.53) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 259.03) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 257.78) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 256.29) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 255.79) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 160.52) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 160.14) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 156.88) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 156.01) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 154.52) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 153.27) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 151.77) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 151.28) rotate(-90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 123.43) rotate(-90) scale(0.58)"
        >
          2{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 121.44) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 118.19) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 117.32) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 115.82) rotate(-90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(5.88 114.57) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 113.08) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(5.88 112.59) rotate(-90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(7.55 17.31) rotate(-90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(5.88 16.93) rotate(-90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 13.68) rotate(-90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(5.88 12.81) rotate(-90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(4.22 11.31) rotate(-90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 10.89) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(1074.12 12.14) rotate(90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 13.63) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(1074.12 14.13) rotate(90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 109.4) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 109.78) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 113.03) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(1074.12 113.9) rotate(90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 115.39) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 116.64) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 118.14) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 118.63) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 146.48) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 148.46) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 151.71) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 152.59) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 154.08) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 155.33) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 156.83) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 157.32) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 252.59) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 252.97) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 256.22) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 257.09) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 258.58) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 259.84) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 261.33) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 261.82) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 265.07) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 265.45) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 268.7) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 269.57) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 271.07) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 272.32) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 273.81) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(1074.12 274.3) rotate(90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 302.15) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 304.14) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 307.39) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 308.26) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 309.75) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text className="cls-8" transform="translate(1074.12 311) rotate(90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 312.5) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 312.99) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 408.26) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 408.64) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 411.89) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 412.76) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 414.26) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 415.51) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 417) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(1074.12 417.5) rotate(90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 420.74) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 421.12) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 424.37) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 425.24) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 426.74) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 427.99) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 429.48) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 429.98) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 457.83) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 459.81) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 463.06) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 463.93) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 465.43) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 466.68) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 468.17) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 468.66) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 563.94) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 564.32) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 567.56) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 568.44) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 569.93) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 571.18) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 572.68) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 573.17) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 576.42) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text className="cls-8" transform="translate(1074.12 576.8) rotate(90)">
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 580.05) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 580.92) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 582.41) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 583.66) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 585.16) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 585.65) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 613.5) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 615.48) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 618.73) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text className="cls-8" transform="translate(1074.12 619.6) rotate(90)">
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 621.1) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 622.35) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 623.84) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 624.34) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 719.61) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 719.99) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 723.24) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 724.11) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 725.6) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 726.85) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 728.35) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 728.84) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 732.09) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 732.47) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 735.72) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 736.59) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 738.09) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 739.34) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 740.83) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 741.32) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 769.17) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 771.16) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 774.41) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 775.28) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 776.77) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 778.02) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 779.52) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 780.01) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 875.28) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 875.66) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 878.91) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 879.78) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 881.28) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 882.53) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 884.02) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 884.51) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 887.76) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 888.14) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 891.39) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 892.26) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 893.76) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 895.01) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 896.5) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(1074.12 897) rotate(90)">
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 924.84) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 926.83) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 930.08) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 930.95) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 932.44) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 933.69) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 935.19) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 935.68) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1030.96) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1031.34) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1034.59) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1035.46) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1036.95) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1038.2) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1039.7) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1040.19) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1043.44) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1043.82) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1047.07) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1047.95) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1049.44) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1050.69) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1052.19) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1052.68) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1080.53) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1082.52) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1085.77) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1086.64) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1088.14) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1089.39) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1090.88) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1091.37) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1186.65) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1187.03) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1190.28) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1191.15) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1192.65) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1193.9) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1195.39) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1195.89) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1223.74) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1225.73) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1228.98) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1229.85) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1231.34) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1232.59) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1234.09) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1234.58) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1329.86) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1330.24) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1333.49) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1334.36) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1335.86) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1337.11) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1338.6) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1339.09) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1366.95) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1368.93) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1372.19) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1373.06) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1374.55) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1375.8) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1377.3) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1377.79) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1473.07) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1473.45) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1476.7) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1477.57) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1479.07) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1480.32) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1481.81) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1482.3) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1510.16) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1512.14) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1515.39) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1516.26) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1517.76) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1519.01) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1520.5) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text className="cls-8" transform="translate(1074.12 1521) rotate(90)">
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1616.28) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1616.65) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1619.91) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1620.78) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1622.27) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1623.52) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1625.02) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1625.51) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1653.36) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1655.35) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1658.6) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1659.47) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1660.97) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1662.22) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1663.71) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1664.2) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1759.48) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1759.86) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1763.11) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1763.99) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1765.48) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1766.73) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1768.23) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1768.72) rotate(90)"
        >
          <tspan xmlSpace="preserve"> Absorb CO</tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1796.57) rotate(90) scale(0.58)"
        >
          2{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1798.56) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1801.81) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1802.68) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1804.18) rotate(90) scale(0.58)"
        >
          <tspan xmlSpace="preserve">. </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1805.43) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1806.92) rotate(90) scale(0.58)"
        >
          .
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1807.41) rotate(90)"
        >
          <tspan xmlSpace="preserve">
            {" "}
            Only Valid as Digital Non-Fungible Token
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1072.45 1902.69) rotate(90) scale(0.58)"
        >
          {" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1903.07) rotate(90)"
        >
          <tspan xmlSpace="preserve"> </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1906.32) rotate(90) scale(0.58)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1074.12 1907.19) rotate(90)"
        >
          .{" "}
        </text>
        <text
          className="cls-8"
          transform="translate(1075.78 1908.69) rotate(90) scale(0.58)"
        >
          .
        </text>
        <g>
          <text
            className="cls-9"
            transform="translate(774.04 350.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(777.59 338.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(783.67 350.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(787.22 338.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(801.26 350.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(804.8 338.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(810.89 350.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(814.43 338.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(828.47 350.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(832.02 338.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(838.1 350.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(841.65 338.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(855.69 350.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(859.24 338.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(865.32 350.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(868.87 338.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(882.9 350.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(886.45 338.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(892.53 350.7) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 908.28, 335.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 911.83, 347.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 917.91, 335.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 921.46, 347.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 935.5, 335.27)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 939.04, 347.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 945.13, 335.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 948.67, 347.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 962.71, 335.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 966.26, 347.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 972.34, 335.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 975.89, 347.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 989.93, 335.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 993.47, 347.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 999.56, 335.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 1003.1, 347.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1017.14, 335.27)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 1020.69, 347.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1026.77, 335.27)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1037.52, 36.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1033.97, 24.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1027.89, 36.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1024.34, 24.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 1010.3, 36.5)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 1006.75, 24.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1000.67, 36.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 997.12, 24.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 983.08, 36.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 979.54, 24.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 973.45, 36.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 969.91, 24.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 955.87, 36.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 952.32, 24.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 946.24, 36.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 942.69, 24.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 928.65, 36.5)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 925.1, 24.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 919.02, 36.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(903.28 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(899.73 33.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(893.65 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(890.1 33.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(876.06 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(872.51 33.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(866.43 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(862.88 33.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(848.84 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(845.3 33.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(839.21 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(835.67 33.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(821.63 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(818.08 33.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(812 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(808.45 33.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(794.41 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(790.87 33.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(784.78 21.07) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(1067.72 313.28) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 309.73) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 303.65) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 300.1) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(1067.72 286.06) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1055.73 282.51) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 276.43) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 272.88) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 258.84) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 255.3) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 249.21) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 245.67) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 231.63) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 228.08) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 222) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 218.45) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 204.41) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1055.73 200.86) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 194.78) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 179.04)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 175.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 169.41)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 165.86)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 151.83)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1064.27, 148.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 142.2)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 138.65)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 124.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 121.06)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 114.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 111.43)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 97.39)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 93.85)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 87.76)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 84.22)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 70.18)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1064.27, 66.63)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 60.55)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(60.04 348.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(63.59 336.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(69.67 348.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(73.22 336.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(87.26 348.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(90.8 336.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(96.89 348.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(100.43 336.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(114.47 348.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(118.02 336.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(124.1 348.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(127.65 336.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(141.69 348.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(145.24 336.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(151.32 348.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(154.87 336.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(168.9 348.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(172.45 336.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(178.53 348.7) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 194.28, 333.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 197.83, 345.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 203.91, 333.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 207.46, 345.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 221.5, 333.27)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 225.04, 345.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 231.13, 333.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 234.67, 345.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 248.71, 333.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 252.26, 345.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 258.34, 333.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 261.89, 345.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 275.93, 333.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 279.47, 345.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 285.56, 333.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 289.1, 345.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 303.14, 333.27)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 306.69, 345.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 312.77, 333.27)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 323.52, 34.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 319.97, 22.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 313.89, 34.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 310.34, 22.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 296.3, 34.5)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 292.75, 22.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 286.67, 34.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 283.12, 22.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 269.08, 34.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 265.54, 22.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 259.45, 34.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 255.91, 22.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 241.87, 34.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 238.32, 22.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 232.24, 34.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 228.69, 22.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 214.65, 34.5)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 211.1, 22.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 205.02, 34.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(189.28 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(185.73 31.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(179.65 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(176.1 31.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(162.06 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(158.51 31.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(152.43 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(148.88 31.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(134.84 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(131.3 31.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(125.21 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(121.67 31.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(107.63 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(104.08 31.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(98 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(94.45 31.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(80.41 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(76.87 31.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(70.78 19.07) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 311.15)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 30.41, 307.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 301.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 30.41, 297.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 283.94)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 30.41, 280.39)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 274.31)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 30.41, 270.76)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 256.72)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 30.41, 253.17)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 247.09)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 30.41, 243.54)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 229.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 30.41, 225.96)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 219.87)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 30.41, 216.33)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 202.29)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 30.41, 198.74)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 18.42, 192.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(33.85 176.92) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(21.86 173.37) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(33.85 167.29) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(21.86 163.74) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(33.85 149.7) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(21.86 146.16) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(33.85 140.07) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(21.86 136.53) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(33.85 122.49) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(21.86 118.94) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(33.85 112.86) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(21.86 109.31) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(33.85 95.27) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(21.86 91.72) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(33.85 85.64) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(21.86 82.09) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(33.85 68.05) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(21.86 64.51) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(33.85 58.42) rotate(-90) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(59.04 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(62.59 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(68.67 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(72.22 1052.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(86.26 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(89.8 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(95.89 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(99.43 1052.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(113.47 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(117.02 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(123.1 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(126.65 1052.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(140.69 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(144.24 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(150.32 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(153.87 1052.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(167.9 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(171.45 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(177.54 1064.7) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 193.28, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 196.83, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 202.91, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 206.46, 1061.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 220.5, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 224.04, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 230.13, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 233.67, 1061.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 247.71, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 251.26, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 257.34, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 260.89, 1061.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 274.93, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 278.47, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 284.56, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 288.1, 1061.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 302.14, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 305.69, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 311.77, 1049.27)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 322.52, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 318.97, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 312.89, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 309.34, 738.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 295.3, 750.5)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 291.75, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 285.67, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 282.12, 738.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 268.08, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 264.54, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 258.45, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 254.91, 738.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 240.87, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 237.32, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 231.24, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 227.69, 738.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 213.65, 750.5)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 210.1, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 204.02, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(188.28 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(184.73 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(178.65 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(175.1 747.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(161.06 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(157.51 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(151.43 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(147.88 747.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(133.84 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(130.3 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(124.21 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(120.67 747.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(106.63 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(103.08 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(97 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(93.45 747.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(79.41 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(75.87 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(69.78 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 1027.15)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 29.41, 1023.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 1017.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 29.41, 1013.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 999.94)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 29.41, 996.39)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 990.31)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 29.41, 986.76)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 972.72)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 29.41, 969.17)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 963.09)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 29.41, 959.54)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 945.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 29.41, 941.96)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 935.87)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 29.41, 932.33)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 918.29)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 29.41, 914.74)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 17.42, 908.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.85 892.92) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.86 889.37) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.85 883.29) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.86 879.74) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(32.85 865.7) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(20.86 862.16) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.85 856.07) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.86 852.53) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(32.85 838.49) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.86 834.94) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.85 828.86) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.86 825.31) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(32.85 811.27) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.86 807.72) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.85 801.64) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.86 798.09) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(32.85 784.05) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(20.86 780.51) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.85 774.42) rotate(-90) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(352.72 1027.28) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(340.73 1023.73) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(352.72 1017.65) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(340.73 1014.1) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(352.72 1000.06) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(340.73 996.51) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(352.72 990.43) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(340.73 986.88) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(352.72 972.84) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(340.73 969.3) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(352.72 963.21) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(340.73 959.67) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(352.72 945.63) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(340.73 942.08) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(352.72 936) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(340.73 932.45) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(352.72 918.41) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(340.73 914.86) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(352.72 908.78) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 893.04)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 349.27, 889.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 883.41)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 349.27, 879.86)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 865.83)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 349.27, 862.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 856.2)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 349.27, 852.65)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 838.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 349.27, 835.06)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 828.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 349.27, 825.43)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 811.39)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 349.27, 807.85)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 801.76)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 349.27, 798.22)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 784.18)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 349.27, 780.63)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 337.29, 774.55)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(774.04 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(777.59 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(783.67 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(787.22 1052.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(801.26 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(804.8 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(810.89 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(814.43 1052.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(828.47 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(832.02 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(838.1 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(841.65 1052.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(855.69 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(859.24 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(865.32 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(868.87 1052.71)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(882.9 1064.7) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(886.45 1052.71)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(892.54 1064.7) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 908.28, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 911.83, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 917.91, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 921.46, 1061.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 935.5, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 939.04, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 945.13, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 948.67, 1061.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 962.71, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 966.26, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 972.34, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 975.89, 1061.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 989.93, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 993.47, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 999.56, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 1003.11, 1061.26)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1017.14, 1049.27)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 1020.69, 1061.26)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1026.77, 1049.27)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1037.52, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1033.97, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1027.89, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1024.34, 738.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 1010.3, 750.5)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 1006.75, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1000.67, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 997.12, 738.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 983.08, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 979.54, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 973.45, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 969.91, 738.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 955.87, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 952.32, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 946.24, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 942.69, 738.51)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 928.65, 750.5)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 925.1, 738.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 919.02, 750.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(903.28 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(899.73 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(893.65 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(890.1 747.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(876.06 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(872.51 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(866.43 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(862.88 747.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(848.84 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(845.3 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(839.21 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(835.67 747.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(821.63 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(818.08 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(812 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(808.45 747.06) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(794.41 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(790.87 747.06) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(784.78 735.07) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 1027.15)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 744.41, 1023.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 1017.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 744.41, 1013.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 999.94)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 744.41, 996.39)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 990.31)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 744.41, 986.76)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 972.72)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 744.41, 969.17)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 963.09)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 744.41, 959.54)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 945.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 744.41, 941.96)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 935.87)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 744.41, 932.33)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 918.29)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 744.41, 914.74)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 732.42, 908.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(747.85 892.92) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(735.86 889.37) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(747.85 883.29) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(735.86 879.74) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(747.85 865.7) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(735.86 862.16) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(747.85 856.07) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(735.86 852.53) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(747.85 838.49) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(735.86 834.94) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(747.85 828.86) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(735.86 825.31) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(747.85 811.27) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(735.86 807.72) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(747.85 801.64) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(735.86 798.09) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(747.85 784.05) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(735.86 780.51) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(747.85 774.42) rotate(-90) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(1067.72 1027.28) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 1023.73) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 1017.65) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 1014.1) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(1067.72 1000.06) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1055.73 996.51) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 990.43) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 986.88) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 972.84) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 969.3) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 963.21) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 959.67) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 945.63) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 942.08) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 936) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1055.73 932.45) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 918.41) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1055.73 914.86) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1067.72 908.78) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 893.04)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 889.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 883.41)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 879.86)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 865.83)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1064.27, 862.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 856.2)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 852.65)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 838.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 835.06)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 828.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 825.43)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 811.39)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 807.85)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 801.76)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1064.27, 798.22)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 784.18)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1064.27, 780.63)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1052.29, 774.55)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(410.04 1423.09) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(413.59 1411.1)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(419.67 1423.09) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(423.22 1411.1)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(437.26 1423.09) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(440.8 1411.1)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(446.89 1423.09) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(450.43 1411.1)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(464.47 1423.09) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(468.02 1411.1)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(474.1 1423.09) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(477.65 1411.1)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(491.69 1423.09) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(495.24 1411.1)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(501.32 1423.09) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(504.87 1411.1)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(518.9 1423.09) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(522.45 1411.1)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(528.54 1423.09) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 544.28, 1407.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 547.83, 1419.65)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 553.91, 1407.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 557.46, 1419.65)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 571.5, 1407.66)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 575.04, 1419.65)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 581.13, 1407.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 584.67, 1419.65)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 598.71, 1407.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 602.26, 1419.65)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 608.34, 1407.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 611.89, 1419.65)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 625.93, 1407.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 629.47, 1419.65)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 635.56, 1407.66)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 639.11, 1419.65)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 653.14, 1407.66)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 656.69, 1419.65)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 662.77, 1407.66)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(413.09 349.24) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(416.64 337.25)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(422.72 349.24) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(426.27 337.25)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(440.31 349.24) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(443.86 337.25)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(449.94 349.24) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(453.49 337.25)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(467.53 349.24) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(471.07 337.25)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(477.16 349.24) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(480.7 337.25)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(494.74 349.24) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(498.29 337.25)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(504.37 349.24) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(507.92 337.25)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(521.96 349.24) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(525.5 337.25)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(531.59 349.24) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 547.33, 333.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 550.88, 345.8)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 556.96, 333.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 560.51, 345.8)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 574.55, 333.81)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 578.09, 345.8)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 584.18, 333.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 587.73, 345.8)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 601.76, 333.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 605.31, 345.8)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 611.39, 333.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 614.94, 345.8)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 628.98, 333.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 632.53, 345.8)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 638.61, 333.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 642.16, 345.8)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 656.2, 333.81)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 659.74, 345.8)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 665.83, 333.81)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 669.37, 35.04)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 665.83, 23.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 659.74, 35.04)"
          >
            .
          </text>
          <text className="cls-9" transform="matrix(-1, 0, 0, 1, 656.2, 23.05)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 642.16, 35.04)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 638.61, 23.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 632.53, 35.04)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 628.98, 23.05)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 614.94, 35.04)"
          >
            .
          </text>
          <text className="cls-9" transform="matrix(-1, 0, 0, 1, 611.4, 23.05)">
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 605.31, 35.04)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 601.76, 23.05)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 587.73, 35.04)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 584.18, 23.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 578.1, 35.04)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 574.55, 23.05)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 560.51, 35.04)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 556.96, 23.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 550.88, 35.04)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(535.14 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(531.59 31.6) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(525.51 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(521.96 31.6) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(507.92 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(504.37 31.6) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(498.29 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(494.74 31.6) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(480.7 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(477.16 31.6) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(471.07 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(467.53 31.6) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(453.49 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(449.94 31.6) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(443.86 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(440.31 31.6) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(426.27 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(422.72 31.6) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(416.64 19.61) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(768.21 704.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(771.75 692.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(777.84 704.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(781.38 692.8)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(795.42 704.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(798.97 692.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(805.05 704.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(808.6 692.8)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(822.64 704.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(826.18 692.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(832.27 704.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(835.81 692.8)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(849.85 704.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(853.4 692.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(859.48 704.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(863.03 692.8)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(877.07 704.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(880.62 692.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(886.7 704.79) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 902.44, 689.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 905.99, 701.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 912.07, 689.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 915.62, 701.35)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 929.66, 689.36)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 933.21, 701.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 939.29, 689.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 942.84, 701.35)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 956.88, 689.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 960.42, 701.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 966.51, 689.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 970.05, 701.35)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 984.09, 689.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 987.64, 701.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 993.72, 689.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 997.27, 701.35)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1011.31, 689.36)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 1014.86, 701.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1020.94, 689.36)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1031.68, 390.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1028.13, 378.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1022.05, 390.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1018.5, 378.61)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 1004.46, 390.59)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 1000.92, 378.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 994.83, 390.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 991.29, 378.61)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 977.25, 390.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 973.7, 378.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 967.62, 390.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 964.07, 378.61)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 950.03, 390.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 946.49, 378.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 940.4, 390.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 936.85, 378.61)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 922.82, 390.59)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 919.27, 378.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 913.19, 390.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(897.44 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(893.89 387.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(887.81 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(884.26 387.15) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(870.23 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(866.68 387.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(860.59 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(857.05 387.15) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(843.01 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(839.46 387.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(833.38 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(829.83 387.15) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(815.79 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(812.25 387.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(806.16 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(802.62 387.15) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(788.58 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(785.03 387.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(778.95 375.17) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(1061.88 667.37) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1049.89 663.82) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1061.88 657.74) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1049.89 654.19) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(1061.88 640.15) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1049.89 636.61) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1061.88 630.52) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1049.89 626.98) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1061.88 612.94) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1049.89 609.39) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1061.88 603.31) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1049.89 599.76) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1061.88 585.72) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1049.89 582.17) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1061.88 576.09) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1049.89 572.54) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1061.88 558.51) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1049.89 554.96) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1061.88 548.88) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 533.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1058.44, 529.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 523.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1058.44, 519.96)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 505.92)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1058.44, 502.37)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 496.29)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1058.44, 492.74)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 478.7)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1058.44, 475.16)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 469.07)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1058.44, 465.53)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 451.49)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1058.44, 447.94)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 441.86)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1058.44, 438.31)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 424.27)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1058.44, 420.73)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1046.45, 414.64)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(54.21 702.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(57.75 690.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(63.84 702.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(67.38 690.8)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(81.42 702.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(84.97 690.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(91.05 702.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(94.6 690.8)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(108.64 702.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(112.18 690.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(118.27 702.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(121.81 690.8)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(135.85 702.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(139.4 690.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(145.48 702.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(149.03 690.8)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(163.07 702.79) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(166.62 690.8)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(172.7 702.79) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 188.44, 687.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 191.99, 699.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 198.07, 687.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 201.62, 699.35)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 215.66, 687.36)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 219.21, 699.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 225.29, 687.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 228.84, 699.35)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 242.88, 687.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 246.42, 699.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 252.51, 687.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 256.05, 699.35)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 270.09, 687.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 273.64, 699.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 279.72, 687.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 283.27, 699.35)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 297.31, 687.36)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 300.86, 699.35)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 306.94, 687.36)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 317.68, 388.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 314.13, 376.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 308.05, 388.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 304.5, 376.61)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 290.46, 388.59)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 286.92, 376.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 280.83, 388.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 277.29, 376.61)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 263.25, 388.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 259.7, 376.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 253.62, 388.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 250.07, 376.61)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 236.03, 388.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 232.49, 376.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 226.4, 388.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 222.85, 376.61)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 208.82, 388.59)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 205.27, 376.61)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 199.19, 388.59)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(183.44 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(179.89 385.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(173.81 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(170.26 385.15) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(156.23 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(152.68 385.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(146.59 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(143.05 385.15) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(129.01 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(125.46 385.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(119.38 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(115.83 385.15) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(101.79 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(98.25 385.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(92.16 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(88.62 385.15) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(74.58 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(71.03 385.15) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(64.95 373.17) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 665.25)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 24.58, 661.7)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 655.62)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 24.58, 652.07)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 638.03)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 24.58, 634.48)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 628.4)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 24.58, 624.85)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 610.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 24.58, 607.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 601.18)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 24.58, 597.64)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 583.6)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 24.58, 580.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 573.97)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 24.58, 570.42)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 556.38)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 24.58, 552.84)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 12.59, 546.75)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(28.02 531.01) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(16.03 527.47) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(28.02 521.38) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(16.03 517.84) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(28.02 503.8) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(16.03 500.25) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(28.02 494.17) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(16.03 490.62) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(28.02 476.58) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(16.03 473.03) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(28.02 466.95) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(16.03 463.4) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(28.02 449.37) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(16.03 445.82) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(28.02 439.73) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(16.03 436.19) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(28.02 422.15) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(16.03 418.6) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(28.02 412.52) rotate(-90) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(407.26 703.33) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(410.81 691.35)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(416.89 703.33) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(420.44 691.35)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(434.47 703.33) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(438.02 691.35)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(444.11 703.33) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(447.65 691.35)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(461.69 703.33) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(465.24 691.35)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(471.32 703.33) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(474.87 691.35)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(488.91 703.33) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(492.45 691.35)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(498.54 703.33) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(502.08 691.35)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(516.12 703.33) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(519.67 691.35)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(525.75 703.33) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 541.5, 687.9)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 545.04, 699.89)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 551.13, 687.9)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 554.67, 699.89)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 568.71, 687.9)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 572.26, 699.89)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 578.34, 687.9)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 581.89, 699.89)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 595.93, 687.9)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 599.48, 699.89)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 605.56, 687.9)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 609.11, 699.89)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 623.15, 687.9)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 626.69, 699.89)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 632.78, 687.9)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 636.32, 699.89)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 650.36, 687.9)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 653.91, 699.89)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 659.99, 687.9)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 663.54, 389.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 659.99, 377.15)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 653.91, 389.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 650.36, 377.15)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 636.32, 389.14)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 632.78, 377.15)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 626.69, 389.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 623.15, 377.15)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 609.11, 389.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 605.56, 377.15)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 599.48, 389.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 595.93, 377.15)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 581.89, 389.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 578.34, 377.15)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 572.26, 389.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 568.71, 377.15)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 554.67, 389.14)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 551.13, 377.15)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 545.04, 389.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(529.3 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(525.75 385.7) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(519.67 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(516.12 385.7) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(502.08 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(498.54 385.7) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(492.45 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(488.91 385.7) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(474.87 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(471.32 385.7) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(465.24 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(461.69 385.7) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(447.65 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(444.11 385.7) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(438.02 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(434.48 385.7) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(420.44 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(416.89 385.7) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(410.81 373.71) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(771.21 1423.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(774.75 1411.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(780.84 1423.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(784.38 1411.72)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(798.42 1423.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(801.97 1411.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(808.05 1423.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(811.6 1411.72)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(825.64 1423.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(829.18 1411.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(835.27 1423.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(838.81 1411.72)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(852.85 1423.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(856.4 1411.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(862.48 1423.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(866.03 1411.72)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(880.07 1423.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(883.62 1411.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(889.7 1423.71) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 905.44, 1408.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 908.99, 1420.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 915.07, 1408.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 918.62, 1420.27)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 932.66, 1408.28)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 936.21, 1420.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 942.29, 1408.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 945.84, 1420.27)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 959.88, 1408.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 963.42, 1420.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 969.51, 1408.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 973.05, 1420.27)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 987.09, 1408.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 990.64, 1420.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 996.72, 1408.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 1000.27, 1420.27)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1014.31, 1408.28)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 1017.86, 1420.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1023.94, 1408.28)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1034.68, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1031.13, 1097.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1025.05, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1021.5, 1097.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 1007.46, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 1003.92, 1097.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 997.83, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 994.29, 1097.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 980.25, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 976.7, 1097.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 970.62, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 967.07, 1097.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 953.03, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 949.49, 1097.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 943.4, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 939.85, 1097.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 925.82, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 922.27, 1097.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 916.19, 1109.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(900.44 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(896.89 1106.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(890.81 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(887.26 1106.07) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(873.23 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(869.68 1106.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(863.59 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(860.05 1106.07) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(846.01 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(842.46 1106.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(836.38 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(832.83 1106.07) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(818.79 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(815.25 1106.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(809.16 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(805.62 1106.07) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(791.58 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(788.03 1106.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(781.95 1094.08) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(1064.88 1386.29) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1052.89 1382.74) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1064.88 1376.65) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1052.89 1373.11) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(1064.88 1359.07) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1052.89 1355.52) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1064.88 1349.44) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1052.89 1345.89) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1064.88 1331.85) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1052.89 1328.31) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1064.88 1322.22) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1052.89 1318.68) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1064.88 1304.64) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1052.89 1301.09) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1064.88 1295.01) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1052.89 1291.46) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1064.88 1277.42) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1052.89 1273.87) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1064.88 1267.79) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1252.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1061.44, 1248.5)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1242.42)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1061.44, 1238.87)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1224.84)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1061.44, 1221.29)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1215.21)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1061.44, 1211.66)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1197.62)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1061.44, 1194.07)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1187.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1061.44, 1184.44)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1170.4)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1061.44, 1166.86)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1160.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1061.44, 1157.23)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1143.19)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1061.44, 1139.64)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1049.45, 1133.56)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(57.21 1421.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(60.75 1409.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(66.84 1421.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(70.38 1409.72)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(84.42 1421.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(87.97 1409.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(94.05 1421.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(97.6 1409.72)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(111.64 1421.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(115.18 1409.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(121.27 1421.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(124.81 1409.72)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(138.85 1421.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(142.4 1409.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(148.48 1421.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(152.03 1409.72)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(166.07 1421.71) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(169.62 1409.72)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(175.7 1421.71) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 191.44, 1406.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 194.99, 1418.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 201.07, 1406.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 204.62, 1418.27)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 218.66, 1406.28)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 222.21, 1418.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 228.29, 1406.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 231.84, 1418.27)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 245.88, 1406.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 249.42, 1418.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 255.51, 1406.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 259.05, 1418.27)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 273.09, 1406.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 276.64, 1418.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 282.72, 1406.28)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 286.27, 1418.27)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 300.31, 1406.28)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 303.86, 1418.27)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 309.94, 1406.28)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 320.68, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 317.13, 1095.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 311.05, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 307.5, 1095.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 293.46, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 289.92, 1095.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 283.83, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 280.29, 1095.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 266.25, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 262.7, 1095.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 256.62, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 253.07, 1095.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 239.03, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 235.49, 1095.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 229.4, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 225.85, 1095.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 211.82, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 208.27, 1095.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 202.19, 1107.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(186.44 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(182.89 1104.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(176.81 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(173.26 1104.07) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(159.23 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(155.68 1104.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(149.59 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(146.05 1104.07) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(132.01 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(128.46 1104.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(122.38 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(118.83 1104.07) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(104.79 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(101.25 1104.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(95.16 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(91.62 1104.07) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(77.58 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(74.03 1104.07) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(67.95 1092.08) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1384.16)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 27.58, 1380.62)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1374.53)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 27.58, 1370.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1356.95)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 27.58, 1353.4)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1347.32)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 27.58, 1343.77)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1329.73)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 27.58, 1326.18)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1320.1)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 27.58, 1316.55)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1302.51)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 27.58, 1298.97)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1292.88)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 27.58, 1289.34)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1275.3)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 27.58, 1271.75)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 15.59, 1265.67)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(31.02 1249.93) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(19.03 1246.38) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(31.02 1240.3) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(19.03 1236.75) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(31.02 1222.71) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(19.03 1219.17) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(31.02 1213.08) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(19.03 1209.54) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(31.02 1195.5) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(19.03 1191.95) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(31.02 1185.87) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(19.03 1182.32) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(31.02 1168.28) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(19.03 1164.73) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(31.02 1158.65) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(19.03 1155.1) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(31.02 1141.06) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(19.03 1137.52) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(31.02 1131.43) rotate(-90) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(410.26 1422.25) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(413.81 1410.26)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(419.89 1422.25) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(423.44 1410.26)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(437.47 1422.25) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(441.02 1410.26)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(447.11 1422.25) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(450.65 1410.26)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(464.69 1422.25) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(468.24 1410.26)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(474.32 1422.25) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(477.87 1410.26)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(491.91 1422.25) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(495.45 1410.26)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(501.54 1422.25) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(505.08 1410.26)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(519.12 1422.25) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(522.67 1410.26)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(528.75 1422.25) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 544.5, 1406.82)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 548.04, 1418.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 554.13, 1406.82)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 557.67, 1418.81)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 571.71, 1406.82)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 575.26, 1418.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 581.34, 1406.82)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 584.89, 1418.81)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 598.93, 1406.82)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 602.48, 1418.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 608.56, 1406.82)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 612.11, 1418.81)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 626.15, 1406.82)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 629.69, 1418.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 635.78, 1406.82)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 639.32, 1418.81)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 653.36, 1406.82)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 656.91, 1418.81)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 662.99, 1406.82)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 666.54, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 662.99, 1096.06)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 656.91, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 653.36, 1096.06)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 639.32, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 635.78, 1096.06)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 629.69, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 626.15, 1096.06)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 612.11, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 608.56, 1096.06)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 602.48, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 598.93, 1096.06)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 584.89, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 581.34, 1096.06)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 575.26, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 571.71, 1096.06)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 557.67, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 554.13, 1096.06)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 548.04, 1108.05)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(532.3 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(528.75 1104.61) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(522.67 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(519.12 1104.61) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(505.08 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(501.54 1104.61) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(495.45 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(491.91 1104.61) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(477.87 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(474.32 1104.61) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(468.24 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(464.69 1104.61) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(450.65 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(447.11 1104.61) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(441.02 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(437.48 1104.61) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(423.44 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(419.89 1104.61) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(413.81 1092.62) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(772.59 1784.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(776.13 1772.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(782.22 1784.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(785.76 1772.43)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(799.8 1784.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(803.35 1772.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(809.43 1784.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(812.98 1772.43)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(827.02 1784.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(830.56 1772.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(836.65 1784.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(840.2 1772.43)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(854.23 1784.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(857.78 1772.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(863.86 1784.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(867.41 1772.43)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(881.45 1784.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(885 1772.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(891.08 1784.42) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 906.82, 1768.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 910.37, 1780.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 916.46, 1768.99)"
          >
            .
          </text>
          <text className="cls-9" transform="matrix(1, 0, 0, -1, 920, 1780.98)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 934.04, 1768.99)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 937.59, 1780.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 943.67, 1768.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 947.22, 1780.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 961.26, 1768.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 964.8, 1780.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 970.89, 1768.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 974.43, 1780.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 988.47, 1768.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 992.02, 1780.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 998.1, 1768.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 1001.65, 1780.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1015.69, 1768.99)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 1019.24, 1780.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 1025.32, 1768.99)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1028.06, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1024.51, 1458.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 1018.43, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 1014.88, 1458.24)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 1000.85, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 997.3, 1458.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 991.21, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 987.67, 1458.24)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 973.63, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 970.08, 1458.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 964, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 960.45, 1458.24)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 946.41, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 942.87, 1458.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 936.78, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 933.24, 1458.24)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 919.2, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 915.65, 1458.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 909.57, 1470.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(893.82 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(890.28 1466.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(884.19 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(880.65 1466.78) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(866.61 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(863.06 1466.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(856.98 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(853.43 1466.78) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(839.39 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(835.84 1466.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(829.76 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(826.21 1466.78) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(812.17 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(808.63 1466.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(802.54 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(799 1466.78) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(784.96 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(781.41 1466.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(775.33 1454.8) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(1066.26 1747) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1054.27 1743.45) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1066.26 1737.37) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1054.27 1733.82) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(1066.26 1719.78) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1054.27 1716.24) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1066.26 1710.15) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1054.27 1706.61) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1066.26 1692.57) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1054.27 1689.02) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1066.26 1682.94) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1054.27 1679.39) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1066.26 1665.35) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1054.27 1661.81) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1066.26 1655.72) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1054.27 1652.18) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(1066.26 1638.14) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(1054.27 1634.59) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(1066.26 1628.51) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1612.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1062.82, 1609.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1603.14)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1062.82, 1599.59)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1585.55)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1062.82, 1582.01)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1575.92)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1062.82, 1572.38)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1558.34)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1062.82, 1554.79)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1548.71)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1062.82, 1545.16)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1531.12)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1062.82, 1527.57)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1521.49)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 1062.82, 1517.94)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1503.9)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 1062.82, 1500.36)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 1050.83, 1494.27)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(46.59 1782.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(50.13 1770.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(56.22 1782.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(59.76 1770.43)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(73.8 1782.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(77.35 1770.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(83.43 1782.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(86.98 1770.43)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(101.02 1782.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(104.56 1770.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(110.65 1782.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(114.2 1770.43)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(128.23 1782.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(131.78 1770.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(137.86 1782.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(141.41 1770.43)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(155.45 1782.42) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(159 1770.43)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(165.08 1782.42) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 180.82, 1766.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 184.37, 1778.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 190.46, 1766.99)"
          >
            .
          </text>
          <text className="cls-9" transform="matrix(1, 0, 0, -1, 194, 1778.98)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 208.04, 1766.99)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 211.59, 1778.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 217.67, 1766.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 221.22, 1778.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 235.26, 1766.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 238.8, 1778.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 244.89, 1766.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 248.43, 1778.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 262.47, 1766.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 266.02, 1778.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 272.1, 1766.99)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 275.65, 1778.98)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 289.69, 1766.99)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 293.24, 1778.98)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 299.32, 1766.99)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 310.06, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 306.51, 1456.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 300.43, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 296.88, 1456.24)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 282.85, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 279.3, 1456.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 273.21, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 269.67, 1456.24)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 255.63, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 252.08, 1456.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 246, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 242.45, 1456.24)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 228.41, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 224.87, 1456.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 218.78, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 215.24, 1456.24)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 201.2, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 197.65, 1456.24)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 191.57, 1468.22)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(175.82 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(172.28 1464.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(166.19 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(162.65 1464.78) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(148.61 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(145.06 1464.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(138.98 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(135.43 1464.78) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(121.39 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(117.84 1464.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(111.76 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(108.21 1464.78) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(94.17 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(90.63 1464.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(84.54 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(81 1464.78) rotate(180)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(66.96 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(63.41 1464.78) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(57.33 1452.8) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1744.88)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 28.96, 1741.33)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1735.25)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 28.96, 1731.7)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1717.66)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 28.96, 1714.12)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1708.03)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 28.96, 1704.48)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1690.45)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 28.96, 1686.9)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1680.82)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 28.96, 1677.27)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1663.23)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 28.96, 1659.68)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1653.6)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -1, -1, 0, 28.96, 1650.05)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1636.01)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(0, -1, -1, 0, 28.96, 1632.47)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0, -0.58, -0.58, 0, 16.97, 1626.38)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.4 1610.64) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.41 1607.1) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.4 1601.01) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.41 1597.47) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(32.4 1583.43) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(20.41 1579.88) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.4 1573.8) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.41 1570.25) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(32.4 1556.21) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.41 1552.66) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.4 1546.58) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.41 1543.03) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(32.4 1529) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.41 1525.45) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.4 1519.36) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(20.41 1515.82) rotate(-90)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(32.4 1501.78) rotate(-90) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(20.41 1498.23) rotate(-90)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(32.4 1492.15) rotate(-90) scale(0.58)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="translate(411.64 1782.96) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(415.19 1770.98)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(421.27 1782.96) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(424.82 1770.98)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(438.86 1782.96) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(442.4 1770.98)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(448.49 1782.96) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(452.03 1770.98)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(466.07 1782.96) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(469.62 1770.98)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(475.7 1782.96) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(479.25 1770.98)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(493.29 1782.96) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(496.83 1770.98)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(502.92 1782.96) scale(0.58)"
          >
            .
          </text>
          <text className="cls-9" transform="translate(506.46 1770.98)">
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(520.5 1782.96) scale(0.58)"
          >
            .
          </text>
          <text className="cls-11" transform="translate(524.05 1770.98)">
            .
          </text>
          <text
            className="cls-9"
            transform="translate(530.13 1782.96) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 545.88, 1767.54)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 549.42, 1779.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 555.51, 1767.54)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 559.06, 1779.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(0.58, 0, 0, -0.58, 573.09, 1767.54)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 576.64, 1779.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 582.72, 1767.54)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 586.27, 1779.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 600.31, 1767.54)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 603.86, 1779.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 609.94, 1767.54)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 613.49, 1779.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 627.53, 1767.54)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 631.07, 1779.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 637.16, 1767.54)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(1, 0, 0, -1, 640.7, 1779.52)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 654.74, 1767.54)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(1, 0, 0, -1, 658.29, 1779.52)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(0.58, 0, 0, -0.58, 664.37, 1767.54)"
          >
            .
          </text>
        </g>
        <g>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 667.92, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 664.37, 1456.78)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 658.29, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 654.74, 1456.78)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="matrix(-0.58, 0, 0, 0.58, 640.7, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 637.16, 1456.78)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 631.07, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 627.53, 1456.78)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 613.49, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 609.94, 1456.78)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 603.86, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 600.31, 1456.78)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 586.27, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 582.73, 1456.78)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 576.64, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-1, 0, 0, 1, 573.1, 1456.78)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 559.06, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="matrix(-1, 0, 0, 1, 555.51, 1456.78)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="matrix(-0.58, 0, 0, 0.58, 549.43, 1468.77)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(533.68 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(530.14 1465.33) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(524.05 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(520.51 1465.33) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-10"
            transform="translate(506.47 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(502.92 1465.33) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(496.84 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(493.29 1465.33) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(479.25 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(475.7 1465.33) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(469.62 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(466.07 1465.33) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(452.03 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(448.49 1465.33) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(442.4 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(438.86 1465.33) rotate(180)"
          >
            <tspan xmlSpace="preserve"> </tspan>
          </text>
          <text
            className="cls-9"
            transform="translate(424.82 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
          <text
            className="cls-11"
            transform="translate(421.27 1465.33) rotate(180)"
          >
            .
          </text>
          <text
            className="cls-9"
            transform="translate(415.19 1453.34) rotate(180) scale(0.58)"
          >
            .
          </text>
        </g>
      </g>
      <g id="method">
        <text className="cls-12" transform="translate(788.48 939.87)">
          CO
        </text>
        <text
          className="cls-12"
          transform="translate(989.98 983.16) scale(0.58)"
        >
          2
        </text>
        <text className="cls-12" transform="translate(63.48 939.87)">
          CO
        </text>
        <text
          className="cls-12"
          transform="translate(264.98 983.16) scale(0.58)"
        >
          2
        </text>
        {selectedMethod}
        <g>
          <image
            width="1042"
            height="1042"
            transform="translate(362.5 722.07) scale(0.34 0.34)"
            xlinkHref={GetImageUncached(url)  && GetImageUncached(url)}

          />
        </g>

        {logo && logo}
      </g>
      <g id="verification">
        {props?.verification?.toUpperCase().includes("ANY") && (
          <g>
            <text className="cls-30" transform="translate(248 1595.45)">
              MULTIPLE
            </text>
            <text className="cls-30" transform="translate(80 1723.69)">
              VERIFICATIONS
            </text>
          </g>
        )}
        {props?.verification?.toUpperCase().includes("ATTEST") && (
          <g>
            <text className="cls-30" transform="translate(313.44 1595.45)">
              {props?.verification?.toUpperCase()}
            </text>
            {verifyLogo}
          </g>
        )}
        {props?.verification?.toUpperCase().includes("INSPECT") && (
          <g>
            <text className="cls-30" transform="translate(273.44 1595.45)">
              {props?.verification?.toUpperCase()}
            </text>
            {verifyLogo}
          </g>
        )}
        {props?.verification?.toUpperCase().includes("AUDIT") && (
          <g>
            <text className="cls-30" transform="translate(353.44 1595.45)">
              {props?.verification?.toUpperCase()}
            </text>
            {verifyLogo}
          </g>
        )}
        {props?.verification?.toUpperCase().includes("CERTIFY") && (
          <g>
            <text className="cls-30" transform="translate(283.44 1595.45)">
              {props?.verification?.toUpperCase()}
            </text>
            {verifyLogo}
          </g>
        )}
      </g>
      <g id="brand">
        <image
          width="3126"
          height="1042"
          transform="translate(50 1100) scale(0.31 0.31)"
          xlinkHref={props && props?.personalLogo ? props.personalLogo : Absorb}
        />
      </g>
      <g id="denomination">
        <text className="cls-52" transform="translate(24 420)">
          TONS
        </text>
        <text className="cls-52" transform="translate(916 420)">
          TONS
        </text>
        <text className="cls-52" transform="translate(24 690)">
          TONS
        </text>
        <text className="cls-52" transform="translate(916 690)">
          TONS
        </text>
        {/* {(carbonValue > 0 && carbonValue < 0.01) && (
          <text className="cls-53" transform="translate(138.52 608.37)">
            {strCarbonValue}
          </text>
        )} */}
        {/* {(carbonValue === 0 || (carbonValue >= 0.01 && carbonValue < 10)) && ( */}
        {strCarbonValue.length <= 4 && (
          <text className="cls-53" transform="translate(318.52 608.37)">
            {strCarbonValue}
          </text>
        )}
        {/* {(carbonValue >=10 && carbonValue < 100) && ( */}
        {strCarbonValue.length === 5 && (
          <text className="cls-53" transform="translate(258.52 608.37)">
            {strCarbonValue}
          </text>
        )}
        {/* {(carbonValue >= 100 && carbonValue < 1000) && ( */}
        {strCarbonValue.length === 6 && (
          <text className="cls-53" transform="translate(198.52 608.37)">
            {strCarbonValue}
          </text>
        )}
        {/* {(carbonValue >= 1000) && ( */}
        {strCarbonValue.length > 6 && (
          <text className="cls-53" transform="translate(138.52 608.37)">
            {strCarbonValue}
          </text>
        )}
        {/* <text
          className="cls-32"
          transform="translate(835.7 538.47) scale(0.58)"
        >
          th
        </text> */}
      </g>
      <g id="year">
        <text className="cls-31" transform="translate(231.3 278.42)">
          {props?.year}
        </text>
      </g>
    </svg>
  );
});

export default PreviewImageTenant;
