import * as ROUTES from "./routes";


const NAVBARITEMS = [
  {
    label: "BUY",
    value: ROUTES.BUY,
    header: true,
    footer: true,
    hasSubMenu: false
  },
  {
    label: "SELL",
    value: ROUTES.SELL,
    header: true,
    footer: true,
    hasSubMenu: false
  },
  {
    label: "MY OFFSETS",
    value: ROUTES.MYOFFSETS,
    header: true,
    footer: true,
    hasSubMenu: false
  },
  {
    label: "Programs",
    value: ROUTES.PROGRAMS,
    header: true,
    footer: true,
    hasSubMenu: true,
    submenu: [
      {
        title: "Affiliates",
        value: "/programs/#affiliates"

      },
      {
        title: "Brands",
        value: "/programs/#brands"

      },
      {
        title: "Verifiers",
        value: "/programs/#verifiers"

      },
    ]
  },
  {
    label: "BLOG",
    value: ROUTES.BLOG,
    header: true,
    footer: true,
    hasSubMenu: false
  },
  {
    label: "FAQ",
    value: ROUTES.FAQ,
    header: false,
    footer: true,
    hasSubMenu: false
  },
  {
    label: "TEAM",
    value: ROUTES.TEAM,
    header: false,
    footer: true,
    hasSubMenu: false
  },
  // {
  //   label: "BRAND",
  //   value: ROUTES.BRAND,
  //   header: true,
  //   footer: false,
  // }
];

export { NAVBARITEMS };
