import socketio from "socket.io-client";
import React from 'react';
import {useSelector} from "react-redux";
const socket_url = process.env.REACT_APP_SOCKET_URL;

function Wallet (){
    const dataState = useSelector((state) => state.dataState);
    const { wallet } = dataState;
    return wallet;
}

function Token (){
    const dataState = useSelector((state) => state.dataState);
    const { token } = dataState;
    return token;
}

export const socket = socketio.connect(socket_url,{
    forceNew: true,
    auth: {
        token: Token,
        address: Wallet?.walletAddress
    },
    autoConnect: true,
});
export const SocketContext = React.createContext();
