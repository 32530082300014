const SIGNING_DOMAIN_NAME = "CarbonEco-Offset-Voucher"
const SIGNING_DOMAIN_VERSION = "1"

export default class LazyMinter {

  constructor({ contract, signer }) {
    this.contract = contract
    this.signer = signer
  }

  async createVoucher(tokenId, uri, owner, verifierAddr, percentagesForVerifiers, affiliateAddr, price, co2_value, mintedDate) {
    const voucher = { tokenId, uri, owner, verifierAddr, percentagesForVerifiers, affiliateAddr, price, co2_value, mintedDate }
    const domain = await this._signingDomain()
    const types = {
      NFTVoucher: [
        { name: "tokenId", type: "uint256" },
        { name: "uri", type: "string" },
        { name: "owner", type: "address" },
        { name: "verifierAddr", type: "address[]" },
        { name: "percentagesForVerifiers", type: "uint[]" },
        { name: "affiliateAddr", type: "address" },
        { name: "price", type: "uint256" },
        { name: "co2_value", type: "uint" },
        { name: "mintedDate", type: "uint" },
      ]
    }
    const signature = await this.signer._signTypedData(domain, types, voucher)
    return {
      ...voucher,
      signature,
    }
  }

  async _signingDomain() {
    if (this._domain != null) {
      return this._domain
    }
    const chainId = await this.contract.getChainID()
    this._domain = {
      name: SIGNING_DOMAIN_NAME,
      version: SIGNING_DOMAIN_VERSION,
      verifyingContract: this.contract.address,
      chainId,
    }
    return this._domain
  }
}