import * as ActionTypes from "./types";

export const setWalletInfo = (wallet) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_WALLET,
    wallet: wallet,
  });
};

export const setSocketState = (socket)=>(dispatch)=>{
    dispatch({
        type: ActionTypes.SET_SOCKET,
        socket: socket,
    });
}

export const setTokenInfo = (token) => (dispatch) => {
    dispatch({
        type: ActionTypes.SET_TOKEN,
        token: token,
    });
}

export const changeAffiliate = (affiliate) => (dispatch) => {
    dispatch({
      type: ActionTypes.CHANGE_AFFILIATE,
      affiliate: affiliate,
    });
  };


export const setTenantInfo = (tenant) => (dispatch) =>{
    dispatch({
        type: ActionTypes.SET_TENANT_INFO,
        tenant: tenant
    })
}