import { combineReducers } from 'redux';

import authReducer from './auth/reducer';
import dataReducer from './data/reducer';
import uiReducer from './ui/reducer';


const rootReducer = combineReducers({
  authState: authReducer,
  dataState: dataReducer,
  uiState: uiReducer
});

export default rootReducer;
