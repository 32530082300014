import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { Order } from "../../components";
import { OFFSETS_TYPE_INDIVIDUAL } from "../../constants/buy_offsets";


const BuyOffsets = () => {
  const history = useHistory();

  const handleClickBuy = (offsets_type, offsets_index) => {
    history.push({
      pathname: ROUTES.BUY,
      state: {
        offsets_type: offsets_type,
        offsets_index: offsets_index
      },
    });
  }

  return (
    <div style={{ background: "rgb(238,249,228)" }}>
      <div className="container" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <div className="row">
          <div className="col-md-12">
            <span className="title">Buy&nbsp;</span>
            <span className="title-emphasis">Offsets</span>
            <div
              style={{ borderLeft: "6px solid #004d00", marginTop: "50px" }}
            >
              <p className="title-description">
                We host a carbon offsets marketplace where consumers and
                businesses can buy and sell to each other.
              </p>
            </div>
          </div>
        </div>
        <Order 
          onClickBuy={handleClickBuy}
        />
        <div className="row">
          <div className="col mb-4">
            <p
              style={{
                color: "#004d00",
                marginTop: "50px",
                fontSize: "32px",
                lineHeight: "1.5em",
                textAlign: "center",
              }}
            >
              Custom Orders
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ textAlign: "center" }}>
            <button 
              className="btn btn-lg"
              style={{
                width: "240px",
                background: "#13863f",
                border: "none",
                borderRadius: "8px",
                fontSize: "18px",
              }}
              onClick={e => handleClickBuy(OFFSETS_TYPE_INDIVIDUAL, 0)}
            >
              BUY NOW
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyOffsets;
