import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastWarning } from "../components";
import * as ROUTES from "../constants/routes";

export const AuthRoute = ({ component: Component, ...rest }) => {
  const dataState = useSelector((state) => state.dataState);
  const { wallet } = dataState;

  if (!wallet || Object.keys(wallet).length === 0) {
    ToastWarning("Please connect your wallet.");
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: ROUTES.LANDING,
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};
