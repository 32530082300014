import * as ActionTypes from "./types";

const initialState = {
  wallet: null,
  affiliate: null,
  tenant: null
};

function dataReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_WALLET: {
      return Object.assign({}, state, {
        wallet: action.wallet,
      });
    }

    case ActionTypes.CHANGE_AFFILIATE: {
      return Object.assign({}, state, {
        affiliate: action.affiliate,
      });
    }

    case ActionTypes.SET_TOKEN: {
      return Object.assign({}, state, {
        token: action.token,
      });
    }
    case ActionTypes.SET_SOCKET: {
      return Object.assign({}, state, {
        socket: action.socket,
      });
    }

    case ActionTypes.SET_TENANT_INFO: {
      return Object.assign({}, state, {
        tenant: action.tenant
      })
    }

    default:
      return state;
  }
}

export default dataReducer;
