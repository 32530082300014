import { useEffect, useState, lazy } from "react";
import { Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";

import { changeAffiliate } from "../redux/actions";

const ProgramsLazy = lazy(() => import("../pages/Programs"));

const AuthRedirect = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const [affiliate, setAffiliate] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    let params = queryString.parse(location.search);
    if (params?.affiliate) {
      setAffiliate(params?.affiliate);
      dispatch(changeAffiliate(params?.affiliate));
    }
  }, [location, dispatch]);

  if (affiliate) {
    return <Route {...rest} render={(props) => <ProgramsLazy {...props} />} />;
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};

export default AuthRedirect;
