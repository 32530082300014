import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL ;


export const submitData = async (data) => {
  const res = await axios.post(`${baseURL}/customer`, data, {withCredentials: true});
  return res.data;
};
export const submitDataTenant = async (data) => {
  const res = await axios.post(`${baseURL}/tenant`, data, {withCredentials: true});
  return res.data;
};

export const updateData = async (data) => {
  const res = await axios.put(`${baseURL}/customer`, data, {withCredentials: true});
  return res.data;
};

export const getPendingOffsetData = async (walletAddress, page, limit) => {
  const res = await axios.get(`${baseURL}/customer/offsets/pending/${walletAddress}?page=${page}&limit=${limit}`, {
    withCredentials: true,
      headers: {
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',

    },
    crossdomain: true
  });
  return res.data;
};

export const getFailedOffsetData = async (walletAddress, page, limit) => {
    const res = await axios.get(`${baseURL}/customer/offsets/failed/${walletAddress}?page=${page}&limit=${limit}`, {
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',

        },
        crossdomain: true
    });
    return res.data;
};

export const getSuccessfulOffsetData = async (walletAddress, page, limit) => {
    const res = await axios.get(`${baseURL}/customer/offsets/success/${walletAddress}?page=${page}&limit=${limit}`, {
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',

        },
        crossdomain: true
    });
    return res.data;
};

export const getMethod = async () => {
  const res = await axios.get(`${baseURL}/method`, {
      withCredentials: true,
      crossdomain: true
  });
  return res.data;
};

export const getAllOffset = async (method) => {
  const res = await axios.get(`${baseURL}/offset`, {
      withCredentials: true,
      crossdomain: true
  });
  return res.data;
};

export const getOffset = async (method) => {
  const res = await axios.post(`${baseURL}/api/offset`, {
    params: { method: method },
  }, {
      withCredentials: true,
      crossdomain: true
  });
  return res.data;
};

export const getVerifiedData = async () => {
  const res = await axios.get(`${baseURL}/customer`, {withCredentials: true});
  return res.data;
};

export const updateMint = async (data) => {
  const res = await axios.put(`${baseURL}/mint`, data, {
      withCredentials: true,
      crossdomain: true
  });
  return res.data;
};

export const getPrice = async () => {
  const res = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD');
  return res;
};

export const createVoucher = async (data) => {
  const res = await axios.post(`${baseURL}/voucher`, data, {
      withCredentials: true,
      crossdomain: true
  });
  return res.data;
};



export const updateVoucher = async (data) => {
  const res = await axios.put(`${baseURL}/voucher`, data, {
      withCredentials: true,
      crossdomain: true
  });
  return res.data;
};

export const updateVouchers = async (data) => {
  const res = await axios.put(`${baseURL}/vouchers`, data, {
      withCredentials: true,
      crossdomain: true
  });
  return res.data;
};

export const getVoucher = async (data, params) => {
    const res = await axios.get(`${baseURL}/vouchers/${data}`, {
          params: params,
          withCredentials: true,
          crossdomain: true
      });
      return res.data;
};

export const getTenantVoucher = async ( walletId, params) => {
    const res = await axios.get(`${baseURL}/tenant/voucher/${walletId}`, {
        params: params,
        withCredentials: true,
        crossdomain: true
    });
    return res.data;
};

export const getMyTenantVoucher = async (data) => {
    const res = await axios.get(`${baseURL}/tenant/voucher/${data.wallet}`, {
        withCredentials: true,
        crossdomain: true,
        headers: {
            'content-type': 'application/json',
        }
    });
    return res.data;
}

export const getMyVoucher = async (walletAddress, page, limit) => {
  const res = await axios.get(`${baseURL}/voucher/${walletAddress}?page=${page}&limit=${limit}`, {
      withCredentials: true,
      crossdomain: true,
      headers: {
          'content-type': 'application/json',
      }
  });
  return res.data;
}

export const getMyNFTs = async (walletAddress, page, limit) => {
    const res = await axios.get(`${baseURL}/nft/${walletAddress}?page=${page}&limit=${limit}`, {
        withCredentials: true,
        crossdomain: true,
        headers: {
            'content-type': 'application/json',
        }
    });
    return res.data;
}




export const getVoucherByTokenId = async (data) => {
  const res = await axios.get(`${baseURL}/api/get-voucher-by-tokenId`, {
      withCredentials: true,
      crossdomain: true
  });
  return res.data;
}

export const getIndustry = async () => {
  const res = await axios.get(`${baseURL}/industry`, {
      withCredentials: true,
      crossdomain: true
  });
  return res.data;
}

export const requestTenant = async (data) =>{
    const res = await axios.post(`${baseURL}/tenant/init`, data,{
        withCredentials: true,
        crossdomain: true
    })
    return res.data;
}

export const verifyTenant = async (wallet, data) =>{
    const res = await axios.post(`${baseURL}/tenant/${wallet}`, data,{
        withCredentials: true,
        crossdomain: true
    })
    return res.data;
}

export const getMyTenant = async (wallet) =>{
    const res = await axios.get(`${baseURL}/tenant/load/${wallet}`,{
        withCredentials: true,
        crossdomain: true
    })
    return res.data;
}

export const getTenantByName = async (name) =>{
    const res = await axios.get(`${baseURL}/tenant/${name}`, {
        withCredentials: true,
        crossdomain: true
    })
    return res.data;
}

export const createProgram = async (data) =>{
    const res = await axios.post(`${baseURL}/tenant/program/${data.wallet}`, data,{
        withCredentials: true,
        crossdomain: true
    })
    return res.data;
}

export const updateProgram = async (data) =>{
    const res = await axios.put(`${baseURL}/tenant/program/${data.wallet}`, data,{
        withCredentials: true,
        crossdomain: true
    })
    return res.data;
}

export const getProgram = async (wallet) =>{
    const res = await axios.get(`${baseURL}/tenant/program/${wallet}`,{
        withCredentials: true,
        crossdomain: true
    })
    return res.data;
}