import * as ROUTES from "./routes";

const OFFSETS_TYPE_INDIVIDUAL = 0;
const OFFSETS_TYPE_ORGANIZATION = 1;

const OFFSETS_INDIVIDUALS = [
  {
    label: "2 TONS",
    link: `${ROUTES.BUY}?offsets=2`,
  },
  {
    label: "4 TONS",
    link: `${ROUTES.BUY}?offsets=4`,
  },
  {
    label: "6 TONS",
    link: `${ROUTES.BUY}?offsets=6`,
  },
];

const OFFSETS_ORGANIZATIONS = [
  {
    label: "10 TONS",
    link: `${ROUTES.BUY}?offsets=10`,
  },
  {
    label: "100 TONS",
    link: `${ROUTES.BUY}?offsets=100`,
  },
  {
    label: "1000 TONS",
    link: `${ROUTES.BUY}?offsets=1000`,
  },
];

export { 
  OFFSETS_TYPE_INDIVIDUAL,
  OFFSETS_TYPE_ORGANIZATION,
  OFFSETS_INDIVIDUALS, 
  OFFSETS_ORGANIZATIONS 
};
