import React from 'react';
import {useSelector} from "react-redux";
import Logo from "../../../../assets/images/CarbonEco-logo.svg";
import ReactLoading from "react-loading";


const GlobalModel = ()=>{

    const uiState = useSelector((state) => state.uiState);
    const { theme_mode } = uiState;


    return(

        <div style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 77, 0, 0.7)",
            zIndex: 2147483647,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: "fixed",
            display: uiState.global_modal.show
        }} >
            <div style={{
                position: "absolute",
                left: '50%',
                right: "50%",
                top: "50%",
                bottom: "50%",
                WebkitTransform: "translate(-50%, -50%)",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                width: "50%",
                height: "50%",
                borderRadius: "10px"
            }}>
                <div>
                    <div className="row" style={{marginBottom: '10px !important'}}>
                        <div className="col-4 d-flex justify-content-center">
                            <img src={Logo} alt="" style={{width: '150px', margin: '50px auto'}} className={'center-block'} />

                        </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px !important'}}>
                            <div className="col-4 d-flex justify-content-center" style={{marginBottom: '10px !important'}}>
                                <h1 className={'text-center'} style={{marginBottom: '10px !important'}}>
                                    {uiState.global_modal.title}
                                </h1>

                            </div>
                    </div>
                    <div className="row" style={{marginBottom: '10px !important'}}>
                        <div className="col-4 d-flex justify-content-center" style={{marginBottom: '10px !important'}}>
                            <h3 className={'text-center text-warning'} style={{marginBottom: '10px !important'}}>
                                {uiState.global_modal.description}
                            </h3>

                        </div>

                    </div>
                    <div style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        minHeight: "100px",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    >
                        <ReactLoading
                            type="bars"
                            color={theme_mode === "dark" ? "#FFFFFF" : "#004000"}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GlobalModel;