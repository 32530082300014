//import { BLOGS } from "../../constants/blogs";
import React, {useEffect, useState} from "react";
import {getBlogData} from "../../services/mediumService";
//import * as url from "url";
import {ToastError} from "../Toast";
import ToText from "../../utils/ToText";

const Resources = () => {
  const [blogItems, setBlogItems] = useState([]);
  const activeURL = process.env.REACT_APP_HOME_ARTICLES
  //console.log(activeURL)
  const mediumPosts = ()=> {
    getBlogData().then((r) => {
      setBlogItems(r.items);
       //console.log(r)
    }).catch((e) => {
      ToastError('There was problem loading out blog articles..')
      //console.log(e)
    });
  }

  useEffect(()=>{
    mediumPosts();
  }, [setBlogItems])



  return (


        <div className="container mt-5" style={{ marginTop: "50px", marginBottom: "50px" }}>
          <div className="row">
            <div className="col-md-12">
              <div style={{
                color: "#004d00",
                borderLeft: "6px solid #004d00",
                float: "left",
                paddingLeft: "25px",
                marginTop: "10px",
              }}>
              <span className="title">Blog</span>
              </div>
            </div>
            <div className="col-md-12"/>
            <div className="col-md-12"/>
            <div className="col-md-12"/>
          </div>
          <div className="row m-1">

            {blogItems? blogItems.map((v, i)=>{
              if(activeURL.includes(i)) {
                //console.log(v);
                return (
                    <div className="col-md-4" key={i}>
                      <div className="card">
                        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                          <div className="d-block blur-shadow-image">
                            <img src={v.thumbnail} alt="img-blur-shadow" style={{height: "200px"}}
                                 className="img-raised shadow border-radius-lg"/>
                          </div>
                          <div className="colored-shadow"
                               style={{backgroundImage: `url(${v.thumbnail} )`}}></div>
                        </div>
                      </div>
                      <div className="card-body px-4 pt-2">
                        <a href={v.link}>
                          <h5 className="font-weight-normal mt-3">
                            {v.title}
                          </h5>
                        </a>
                        <p>
                          {ToText(
                              v.description.substring(0, 350)
                          )}...`
                        </p>
                        <div className="card-footer d-flex">
                          <p className="font-weight-normal my-auto"><span style={{color: "#004d00", fontStyle: "bold"}}>Author:</span>
                            <span style={{color: "#004d00", marginLeft: "5px"}} >{v.author}</span></p>
                          <p className="text-sm my-auto"><span style={{color: "#004d00", fontStyle: "bold"}}>Published: </span><span style={{color: "#004d00", marginLeft: "5px"}} >{v.pubDate}</span></p>
                          <p> <span style={{color: "#004d00", fontStyle: "bold"}}>Tags:</span>{v.categories.map((t, ti)=>{
                            return(
                                 <span key={ti} style={{backgroundColor: "#004d00", marginLeft: "5px"}} className="badge bg-gradient-success">{t}</span>
                            )
                          })}</p>
                          <a style={{backgroundColor: "#004d00"}} href={v.link} type="button" className="btn  mt-3 mb-0">Read more</a>

                        </div>
                      </div>

                    </div>
                )
              }
              return i
            }): <></> }
          </div>
        </div>


      /*<div className="container" style={{ marginTop: "50px", marginBottom: "50px" }}>
      <div className="row">
        <div className="col-md-12">
          <span className="title">Blog</span>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-md-12">
          <div style={{ borderLeft: "6px solid #004d00", marginTop: "50px" }}>
            <p
              style={{
                marginRight: "25px",
                marginLeft: "25px",
                fontFamily: "Roboto",
                fontSize: "24px",
                lineHeight: "1.5em",
              }}
            >
              Resources for getting started.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        {BLOGS.map((blog, index) => (
          <div key={index + 1} className="col-md-4">
            <div className="card-image">
              <img
                className="img img-raised"
                src={blog.image}
                alt={`blog1-${index}`}
                loading="lazy"
                style={{ height: "250px", width: "100%" }}
              />
            </div>
            <div className="card-content">
              <h4 className="card-title">
                <a href={blog.link} style={{textDecoration: "none", color: "black", cursor: "pointer"}}>
                  {blog.title}
                </a>
              </h4>
              <p className="card-description">
                {blog.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>*/
  );
};

export default Resources;
