import DownloadIcon from "../../assets/images/download-icon.svg";

const Input = (props) => {
  const {
    type = "text",
    id,
    name,
    value,
    filename,
    placeholder,
    onInput,
    onChange,
    isValid = true,
    isLabelEffect = true,
    isDisabled = false,
  } = props;

  return (
    <div className="custom-input-box"
      style={{
        height: `${type === "file" ? "55px" : "100px"}`,
        border: `2px solid ${isValid ? "#BDBDBD" : "#FF0000"}`,
        backgroundColor: `${type === "file" ? "#FBFBFB" : ""}`,
      }}
    >
      {isLabelEffect ? (
        <label className="custom-input-label">
          {placeholder}
        </label>
      ) : (
        ""
      )}
      <input
        className="custom-input"
        style={{
          opacity: `${type === "file" ? 0 : ""}`
        }}
        type={type}
        id={id}
        name={name}
        value={value}
        disabled={isDisabled}
        placeholder={isLabelEffect ? "" : placeholder}
        onInput={onInput}
        onChange={onChange}
      />
      {type === "file" ? (
        <>
          <div className="custom-input-file-icon">
            <img src={DownloadIcon} alt="" />
          </div>
          <div 
            className="custom-input-file-placeholder"
            style={{
              color: `${filename && filename !== "" ? "#000000": "#ADADAD"}`,
              fontStyle: `${filename && filename !== "" ? "": "italic"}`,
            }}
          >
            {filename && filename !== "" ? filename : placeholder}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
