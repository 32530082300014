import * as ActionTypes from "./types";

const initialState = {
  show_splash: true,
  theme_mode: "day",
  isBlur: false,
  requesting: false,
  global_modal: {
    show: 'none',
    title: '',
    description: ''
  }
};

function uiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_THEME_MODE: {
      return {
        ...state,
        theme_mode: action.theme_mode,
      };
    }

    case ActionTypes.SET_BLUR: {
        return {
          ...state,
          isBlur: action.blur,
        };
      }

    case ActionTypes.SHOW_SPLASH_SCREEN: {
      return {
        ...state,
        show_splash: action.now,
      };
    }

    case ActionTypes.REQUEST_DATA_PENDING: {
      return {
        ...state,
        requesting: true,
      };
    }

    case ActionTypes.REQUEST_DATA_FINISHED: {
      return {
        ...state,
        requesting: false,
      };
    }

    case ActionTypes.SHOW_GLOBAL_MODAL:{
      return {
        ...state,
        global_modal: action.global_modal
      }
    }

    default:
      return state;
  }
}

export default uiReducer;
