import React,{ useCallback, useState, useEffect, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";



import Logo from "../../../assets/images/CarbonEco-logo.svg";
import { connectWallet } from "../../../services/web3Service";
import { shortenAddress } from "../../../services/utilsService";
import { TooltipWrapper} from "../../../components";
import { NAVBARITEMS } from "../../../constants/navbarItem";
import { setThemeMode, setTokenInfo} from "../../../redux/actions";
import * as ROUTES from "../../../constants/routes";
import {useCookies} from "react-cookie";

//import CountdownBanner from "../../../components/Banner/Countdown"; 
import {ToastInfoRealTime, ToastInfoWithLink} from "../../../components/Toast/Toast";
import {SocketContext} from '../../../context/socket';


const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
const cookiePath = process.env.REACT_APP_COOKIE_PATH;



const Navbar = () => {
  // const uiState = useSelector((state) => state.uiState);
  // const { isBlur } = uiState;

  const socket = useContext(SocketContext);




  const dataState = useSelector((state) => state.dataState);
  const { wallet } = dataState;

  const location = useLocation();

  const [, setIsScroll] = useState(window.scrollY);

  const dispatch = useDispatch();

  const [, setCookie] = useCookies(['c0']);




  const changeMode = useCallback(
    (value) => {
      dispatch(setThemeMode(value));
      localStorage.setItem("theme_mode", value);
    },
    [dispatch]
  );

  const handleNavigation = useCallback(() => {
    setIsScroll(window.scrollY);
  }, []);


    useEffect(() => {
      handleNavigation();
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (e) => {
          const newColorScheme = e.matches ? "dark" : "day";
          changeMode(newColorScheme);
          localStorage.setItem("theme_mode", newColorScheme);
        });

      if (localStorage.getItem("theme_mode")) {
        changeMode(localStorage.getItem("theme_mode"));
      }

    window.addEventListener("scroll", () => handleNavigation());
    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", () => handleNavigation());
    };
  }, [dispatch, changeMode, handleNavigation]);

  const onConnectWallet = async () => {
    //console.log("onConnectWallet :", wallet);
    if (!wallet) {
      let res = await connectWallet(dispatch);
      await setCookie('token', res.token, { path: cookiePath.toString(), domain: cookieDomain.toString()});
      await dispatch(setTokenInfo(res.token));
      await setWelcome(true);
    }
  };

  const shortenedAddress = useMemo(
    () =>
      wallet && wallet.walletAddress
        ? shortenAddress(wallet.walletAddress)
        : "",
    [wallet]
  );
  const [, setWelcome] = useState(false);
 // const [joined, setJoined] = useState(false);

 /* const handleInviteAccepted = useCallback(() => {
    setJoined(true);
  }, []);*/



  useEffect(() => {
    // as soon as the component is mounted, do the following tasks:
    socket.on('listed-state', function (message){
      //console.log("listed-state: ",message);
      ToastInfoWithLink(message, process.env.REACT_APP_VERIFY_APP_URL);
    });
    socket.on('purchased-state', function (message){
      //console.log("listed-state: ",message);
      ToastInfoRealTime(message);
    });

    socket.on('minted-state', function (message){
      //console.log("listed-state: ",message);
      ToastInfoWithLink(message, '/buy' );
    });
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket.off();
    };
  }, [socket]);
  return (
    <>
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container" style={{marginTop: "32px"}}>
          <div className="navbar-header">
            <NavLink 
              to={ROUTES.LANDING}
              style={{marginLeft: "16px", marginRight: "32px"}}
            >
              <img src={Logo} alt="" style={{width: '250px'}} />
            </NavLink>
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#carboneco-navbar" aria-expanded="false">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
            </button>
          </div>
        
          <div className="collapse navbar-collapse" id="carboneco-navbar">
            <ul className="nav navbar-nav navbar-right" style={{marginTop: "30px", marginBottom: "16px", marginRight: "8px"}}>
              {NAVBARITEMS
                .filter(item => item.header)
                .map((item, index)=> {
                    if(!item.hasSubMenu){
                       return( <li
                            key={"menu-" + index}
                            className={`${location?.pathname?.includes(item.value) ? 'active' : ''}`}
                            style={{marginLeft: "16px"}}
                        >
                            <NavLink
                                to={item.value}
                                style={{
                                    fontSize: "16px",
                                    padding: "8px",
                                    marginRight: "16px",
                                    color: `${location?.pathname?.includes(item.value) ? '#13863f' : '#004000'}`,
                                    borderRadius: 0,
                                    borderBottom: `${location?.pathname?.includes(item.value) ? '2px solid #13863f' : '#004000'}`
                                }}
                            >
                                {item.label}
                            </NavLink>
                        </li>)
                    } else{
                        return(<li key={index} className={`${location?.pathname?.includes(item.value) ? 'active' : ''}`} style={{
                            fontSize: "16px",
                            marginRight: "0px",
                            marginLeft: "8px",
                        }}>
                            <a className="nav-link dropdown-toggle" href={item.value} id="navbarDropdownMenuLink"
                               data-toggle="dropdown"  aria-expanded="false"
                               style={{
                                   fontSize: "16px",
                                   padding: "8px",
                                   marginRight: "16px",
                                   color: `${location?.pathname?.includes(item.value) ? '#13863f' : '#004000'}`,
                                   borderRadius: 0,
                                   borderBottom: `${location?.pathname?.includes(item.value) ? '2px solid #13863f' : '#004000'}`
                               }}
                            >
                                {item.label}
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

                                {item.submenu.map((subItem, subIndex)=>{
                                   return(<p

                                       key={subIndex}
                                   > <NavLink style={{
                                       fontSize: "16px",
                                       padding: "8px",
                                       margin: "4px",
                                       color: `${location?.pathname?.includes(subItem.value) ? '#13863f' : '#004000'}`,
                                       borderRadius: 0,
                                       borderBottom: `${location?.pathname?.includes(subItem.value) ? '2px solid #13863f' : '#004000'}
                                       `
                                   }} className="" to={subItem.value}>{subItem.title}</NavLink></p>)
                                })}
                            </div>
                        </li>)
                    }
                })
              }
              <li className="button-container" style={{marginTop: "-8px"}}>
                <button className="btn btn-round"
                  style={{backgroundColor: "#004d00", color: "#ffffff"}}
                  onClick={wallet ? null : onConnectWallet}
                >
                  {wallet ? (
                    <TooltipWrapper title={wallet.walletAddress}>
                      <span style={{fontSize: "16px"}}>{shortenedAddress}</span>
                    </TooltipWrapper>
                  ) : (
                    <span style={{fontSize: "16px"}}>Connect Wallet</span>
                  )}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container" style={{marginTop: "150px", textAlign: "center"}}>
        <div className="">
        </div>
      </div>
    </>
  );

};

export default Navbar;
