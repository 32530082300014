import { toast } from "react-toastify";
//import Logo from "../../assets/images/carbon.png";

export const ToastSuccess = (text) => {
  toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const ToastError = (text) => {
  toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const Toast = (text) => {
  toast(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const ToastWarning = (text) => {
  toast.warn(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};


export const ToastInfoRealTime =(text)=>{
  toast.success(text, {
    position: 'bottom-left',
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,

  })
}

export const ToastInfoWithLink =(text, link)=>{
  toast.success(text, {
    position: 'bottom-left',
    autoClose: 15000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    onClick: ()=>{ window.open(link, "_blank")}

  })
}