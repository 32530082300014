export const LANDING        = '/';
export const SIGN_UP        = '/sign-up';
export const SIGN_IN        = '/sign-in';
export const BUY            = '/buy';
export const SELL           = '/sell';
export const TENANT_BUY            = '/:brand/buy';
export const TENANT_SELL           = '/:brand/sell';
export const BRAND           = '/register-brand';
export const PROFILE        = '/profile';
export const MYOFFSETS      = '/myoffsets';
export const MYOFFSETS_MY_NFT      = '/myoffsets/nft';
export const MYOFFSETS_MY_FAILED      = '/myoffsets/failed';
export const MYOFFSETS_MY_SUCCESS      = '/myoffsets/success';
export const MYOFFSETS_MY_REVIEW      = '/myoffsets/review';
export const MYOFFSETS_MY_MINT      = '/myoffsets/mint';
export const MINTED_DETAIL  = '/myoffsets/minted/details';
export const MINT           = '/mint';
export const BLOG           = '/blog';
export const FAQ            = '/faq';
export const TEAM           = '/team';
export const JOBS           = '/jobs';
export const JOB_DETAIL     = '/myoffsets/nft/detail';
export const WHITEPAPER     = '/whitepaper';
export const PROGRAMS       = '/programs';
export const EVENT_PLANNER  = '/event-planner';